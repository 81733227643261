import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "./../../../../utils/http";
import "./css/Auth.scss";
// Common components
import Checkbox from "../../../common/others/Checkbox";
import ModalWrapper from "../../../common/others/ModalWrapper";
import PhoneNumberInput from "../../../common/phone-number-input/PhoneNumberInput";
import TextInput from "../../../common/others/TextInput";
// Components common between auth forms
import TabNavigator from "../common-sub-components/TabNavigator";
// Form validation
import {Formik} from "formik";
import {authSchemas} from "../../../../utils/formValidationSchemas";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import CircleLoading from "../../../common/others/CircleLoading";
import UsernameLogin from "./UsernameLogin";
import Api from "../../../../utils/api";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";

const AuthModal = () => {
    const {t} = useTranslation();
    const {modalsDisplayStatus, toggleModals, setAuthValues, authValues, setMaxConnections} = useContext(NavbarContext);
    const [tab, setTab] = useState(authValues.userType);
    const [loading, setLoading] = useState(false);
    const [showInviteCodeInput, setShowInviteCodeInput] = useState(false);
    const [rulesError, setRulesError] = useState(false);
    const dispatch = useDispatch();

    function toggle() {
        toggleModals({...modalsDisplayStatus, auth: false});
    }

    async function submit(values) {
        if (!values.terms) {
            setRulesError(true)
            return;
        }
        if (loading)
            return;
        setAuthValues({
            phoneNumber: values.phoneNumber,
            dialCode: values.dialCode,
            inviteCode: values.inviteCode,
            terms: values.terms,
            userType: tab.toLowerCase()
        });
        setLoading(true);
        try {
            const {data} = await axios.post(getRouter(API.auth.otp, {
                userType: tab.toLowerCase()
            }), {
                mobile: values.phoneNumber,
                country_code: values.dialCode.replace("+", "")
            });

            if (data.success) {
                toggleModals({...modalsDisplayStatus, auth: false, otp: true});

                if (data.data?.code)
                    toast(data.data?.code, {type: "success"});
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const handleSetTab = (t) => {
        setTab(t);
        setAuthValues({
            ...authValues,
            userType: t
        });
    };

    const handleLoginWithGoogle = async (response) => {
        if (!response?.code)
            return toast(t('Error'), {type: 'error'});

        try {
            const {data} = await axios.post(Api.auth.userGoogleLogin, {
                code: decodeURI(response.code)
            });

            const {user, token} = data.data;
            localStorage.setItem('token', token);
            axios.setAuthorizationHeader(token);
            dispatch(logIn(user));
            toggleModals({...modalsDisplayStatus, auth: false});

        } catch (e) {
            if (e.response?.status === 400 && e.response?.data?.data) {
                const data = e.response?.data?.data;
                setMaxConnections(data);
                toggleModals({...modalsDisplayStatus, auth: false, maxConnection: true});
            }
        }
    }

    const requestLoginWithGoogle = () => {
        /* global google */
        const res = google.accounts.oauth2.initCodeClient({
            client_id: "226773653709-82sbn53ir26e5j44n45rnt78ds7d2btj.apps.googleusercontent.com",
            scope: 'profile email openid',
            callback: handleLoginWithGoogle,
            select_account: true,
            redirect_uri: "https://anubani.com"
        }).requestCode();
    }

    if (tab === "publisher" || tab === "marketer")
        return <ModalWrapper className={"auth-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Log in")}</p>
            <TabNavigator tab={tab} changeTab={(tab) => handleSetTab(tab)}/>

            <UsernameLogin type={tab}/>
        </ModalWrapper>;

    return (
        <ModalWrapper className={"auth-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Login / Signup")}</p>
            <TabNavigator tab={tab} changeTab={(tab) => handleSetTab(tab)}/>
            <p className={"modal-tip"}>
                {t("Please select the country code, and input your phone number.")}
            </p>

            <Formik
                initialValues={{
                    dialCode: authValues.dialCode,
                    phoneNumber: authValues.phoneNumber,
                    inviteCode: authValues.inviteCode,
                    terms: authValues.terms
                }}
                validationSchema={authSchemas}
                onSubmit={(values) => submit(values)}
            >
                {({
                      errors,
                      values,
                      handleChange,
                      setFieldValue,
                      touched,
                      setFieldTouched,
                      handleSubmit
                  }) => (
                    <>
                        <PhoneNumberInput
                            dialCode={values.dialCode}
                            onDialCodeChange={(code) => setFieldValue("dialCode", code)}
                            phoneNumber={values.phoneNumber}
                            onPhoneNumberChange={handleChange("phoneNumber")}
                            error={{
                                message: errors.dialCode || errors.phoneNumber,
                                visibility: touched.dialCode || touched.phoneNumber
                            }}
                        />
                        <Checkbox
                            id={"terms-and-services-agreement"}
                            onChange={(check) => {
                                setRulesError(!check);
                                setFieldValue("terms", check);
                                handleChange("terms");
                            }}
                            checked={values.terms}
                            title={t(
                                "I have accepted the rules regarding the usage of Anobani."
                            )}
                            error={rulesError ? t("For Using Website, Please Accept the Rules") : null}
                        />
                        {(!showInviteCodeInput && !values.inviteCode) && (
                            <button
                                type={"button"}
                                onClick={() => setShowInviteCodeInput(true)}
                            >
                                {t("Add invite code")}
                            </button>
                        )}
                        {(showInviteCodeInput || values.inviteCode) && (
                            <TextInput
                                className={"invite-code-input"}
                                placeholder={t("Invite code")}
                                value={values.inviteCode}
                                onChange={handleChange("inviteCode")}
                                onBlur={() => setFieldTouched("inviteCode")}
                                error={{
                                    message: errors.inviteCode,
                                    visibility: touched.inviteCode
                                }}
                                isNumber
                            />
                        )}

                        <button
                            type={"button"}
                            className={"button primary"}
                            onClick={() => handleSubmit()}
                        >
                            {loading
                                ? <CircleLoading/>
                                : t("Confirm")}
                        </button>
                    </>
                )}
            </Formik>

            <div className={"line"}/>

            <div className={"center"} onClick={requestLoginWithGoogle}>
                {icons.google}
                <p>{t("Sign in using Google account")}</p>
            </div>

            <button onClick={() => toggleModals({...modalsDisplayStatus, auth: false, emailLogin: true})}
                    className={"button center"} style={{
                background: "none",
                border: "none"
            }}>{t("Sign in using e-mail address")}</button>
        </ModalWrapper>
    );
};

export default AuthModal;
