import React from "react";
import { useTranslation } from "react-i18next";
import { useSwiper } from "swiper/react";
import "./SliderButtons.scss";
// Utils
import icons from "../../../../../utils/icons";

const SliderButtons = (props) => {
  const { i18n } = useTranslation();
  const swiper = useSwiper();

  return (
    <>
      <button
        type={"button"}
        onClick={() =>
          i18n.language === "en" ? swiper.slideNext() : swiper.slidePrev()
        }
        className={"slider-button next"}
      >
        {icons.arrowhead}
      </button>

      <button
        type={"button"}
        onClick={() =>
          i18n.language === "en" ? swiper.slidePrev() : swiper.slideNext()
        }
        className={`slider-button previous ${props.large && "large"}`}
      >
        {icons.arrowhead}
      </button>
    </>
  );
};

export default SliderButtons;
