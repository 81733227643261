import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./BookRequestForm.scss";
// Common components
import TextInput from "../common/others/TextInput";
// Form validation
import { Formik } from "formik";
import { bookRequestFormSchemas } from "../../utils/formValidationSchemas";
// Utils
import images from "../../utils/images";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import CircleLoading from "../common/others/CircleLoading";
import { toast } from "react-toastify";

const BookRequestForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);


  async function submit(values) {
    if (loading)
      return;
    setLoading(true)

    try {
      const { data } = await axios.post(API.public.requestBook, {
        book_name: values.name,
        author_name: values.author,
        publisher_name: values.publisher
      });

      if (data.success) {
        toast(t("Your Request has been Submitted"), { type: "success" });
      }

    } catch (e) {

    }
    setLoading(false)

  }

  return (
    <div className={"book-request-form"}>
      <div>
        <p>{t("Request book")}</p>
        <p>
          {t(
            "Request the book you want so we can add it to the store if possible."
          )}
        </p>

        <Formik
          initialValues={{ name: "", author: "", publisher: "" }}
          validationSchema={bookRequestFormSchemas}
          onSubmit={(values) => submit(values)}
        >
          {({
              errors,
              values,
              handleChange,
              touched,
              setFieldTouched,
              handleSubmit
            }) => (
            <>
              <TextInput
                placeholder={t("Book Name")}
                value={values.name}
                onChange={handleChange("name")}
                onBlur={() => setFieldTouched("name")}
                error={{
                  message: errors.name,
                  visibility: touched.name
                }}
              />
              <TextInput
                placeholder={t("Author Name")}
                value={values.author}
                onChange={handleChange("author")}
                onBlur={() => setFieldTouched("author")}
                error={{
                  message: errors.author,
                  visibility: touched.author
                }}
              />
              <TextInput
                placeholder={t("Publisher Name")}
                value={values.publisher}
                onChange={handleChange("publisher")}
                onBlur={() => setFieldTouched("publisher")}
                error={{
                  message: errors.publisher,
                  visibility: touched.publisher
                }}
              />
              <button
                type={"button"}
                className={"button primary"}
                onClick={() => handleSubmit()}
              >
                {loading ? <CircleLoading /> : t("Submit")}
              </button>
            </>
          )}
        </Formik>
      </div>

      <img alt={"Five feet apart"} src={images.fiveFeetApart} />
    </div>
  );
};

export default BookRequestForm;
