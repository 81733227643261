import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useClickOutside} from "@mantine/hooks";
import {Tooltip} from 'react-tooltip'
import "./UserSection.scss";
// Utils
import icons from "../../../../../../utils/icons";
import navbarConfigs from "../../../../../../utils/configs/navbar";
import NavbarContext from "../../../../../../utils/contexts/Navbar";
import {getRouter} from "../../../../../../utils/helpers";

const UserSection = (props) => {
    const {i18n, t} = useTranslation();
    const {modalsDisplayStatus, toggleModals} = useContext(NavbarContext);
    const clientData = useSelector((state) => state.client);
    const [showUserDropdown, setShowUserDropdown] = useState(null);
    const userDropdownRef = useClickOutside(
        () => showUserDropdown && setShowUserDropdown(false)
    );
    const userDropdownClassName = `${
        showUserDropdown === true
            ? "active"
            : showUserDropdown === false
                ? "inactive"
                : undefined
    } ${i18n.language === "en" && "ltr"}`;
    useEffect(() => {
        props.navbarFooterDisplayStatus && setShowUserDropdown(false);
    }, [props.navbarFooterDisplayStatus]);

    return (
        <>
            <div className={"user-section"}>
                <div onClick={() => clientData ? setShowUserDropdown(!showUserDropdown) : toggleModals({
                    ...modalsDisplayStatus,
                    auth: true
                })} ref={userDropdownRef}>
                    {icons.user}

                    {clientData ? (
                        <>
                            <p>{clientData.first_name || t("Welcome")}</p>
                            <div className={userDropdownClassName}>
                                {navbarConfigs.userDropdown.map((item) =>
                                    item.link ? (
                                        !item.role ?
                                            <Link to={getRouter(item.link, {
                                                userType: clientData.role
                                            })} key={item.title}>
                                                {item.icon}
                                                <p>{t(item.title)}</p>
                                            </Link> : (item.role.includes(clientData.role) ?
                                                <Link to={getRouter(item.link, {
                                                    userType: clientData.role
                                                })} key={item.title}>
                                                    {item.icon}
                                                    <p>{t(item.title)}</p>
                                                </Link> : null)
                                    ) : (
                                        <div
                                            key={item.title}
                                            onClick={() =>
                                                toggleModals({
                                                    ...modalsDisplayStatus,
                                                    logOut: true
                                                })
                                            }
                                        >
                                            {item.icon}
                                            <p>{t(item.title)}</p>
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    ) : <p>{t("Login / Signup")}</p>}
                </div>

                <div>
                    {clientData && (
                        <Link data-tooltip-id="notifications"
                              data-tooltip-content={t("Notifications")}
                              data-tooltip-place="bottom"
                              to={"/notifications"}>{icons.alarmsAndNewspaper}</Link>
                    )}
                    {!clientData && <>
                        <button data-tooltip-id="shoppingCart"
                                data-tooltip-content={t("Shopping Cart")}
                                data-tooltip-place="bottom"
                                onClick={() => toggleModals({
                                    ...modalsDisplayStatus,
                                    auth: true
                                })}>{icons.shoppingCart}</button>
                        <button data-tooltip-id="giftCard"
                                data-tooltip-content={t("Gift Card")}
                                data-tooltip-place="bottom" onClick={() => toggleModals({
                            ...modalsDisplayStatus,
                            auth: true
                        })}>{icons.giftCard}</button>
                    </>}

                    {(clientData && clientData.role == 'user') && <>
                        <Link data-tooltip-id="shoppingCart"
                              data-tooltip-content={t("Shopping Cart")}
                              data-tooltip-place="bottom"
                              to={"/cart"}>{icons.shoppingCart}</Link>
                        <Link data-tooltip-id="giftCard"
                              data-tooltip-content={t("Gift Card")}
                              data-tooltip-place="bottom"
                              to={"/gift-card"}>{icons.giftCard}</Link>
                    </>}
                </div>
            </div>
            <Tooltip id='notifications'/>
            <Tooltip id='shoppingCart'/>
            <Tooltip id='giftCard'/>
        </>
    );
};

export default UserSection;
