import React from "react";
import "./SiteSuggestion.scss";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export const SiteSuggestionSkeleton = () => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`site-suggestion ${
        i18n.language === "en" && "ltr"
      }`}
    >
      <Skeleton className={"site-suggestion-skeleton"} />
    </div>
  );
};


const SiteSuggestion = ({ banner }) => {
  const { i18n } = useTranslation();

  return (
    banner.product
      ? <Link to={`products/${banner.product.slug}`}
              className={`site-suggestion ${
                i18n.language === "en" ? "ltr" : "rtl"
              }`}
      >
        <img src={banner.banner_url} alt="" />
      </Link>
      : <div
        className={`site-suggestion ${
          i18n.language === "en" ? "ltr" : "rtl"
        }`}
      >
        <img src={banner.banner_url} alt="" />
      </div>
  );
};

export default SiteSuggestion;
