import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./Footer.scss";
// Sub components
import RightSection from "./sub-components/RightSection";
import LeftSection from "./sub-components/LeftSection";
import Api from "../../../utils/api";
import axios from "../../../utils/http";

const Footer = () => {
    const {i18n} = useTranslation();
    const [info, setInfo] = useState({
        recommendedProducts: [],
        settings: {},
    });

    useEffect(() => {
        getSiteSettings();
    }, []);

    const getSiteSettings = async () => {
        try {
            const {data} = await axios.get(Api.public.siteSettings)

            setInfo({
                recommendedProducts: data.data.recommended_products,
                settings: data.data.settings,
            })
        } catch (e) {

        }
    }

    return (
        <div className={`footer ${i18n.language !== "en" && "ltr"}`}>
            <div className={'footer-primary-light'}>
                <RightSection info={info}/>
                <LeftSection/>
            </div>

            {info.settings.footer_title || info.settings.footer_description ? <div className={'footer-primary'}>
                <p>{info.settings.footer_title}</p>
                <p>
                    {info.settings.footer_description}
                </p>
            </div> : null}
        </div>
    );
};

export default Footer;
