import "./CollectionsModal.scss";
import ModalWrapper from "../../common/others/ModalWrapper";
import TextInput from "../../common/others/TextInput";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "../../../utils/http";
import CircleLoading from "../../common/others/CircleLoading";
import Api from "../../../utils/api";
import {toast} from "react-toastify";

const CollectionsModal = ({toggleDisplay, onCreated, locale}) => {
    const {t} = useTranslation();
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    const handleCreateCollection = async () => {
        if (loading)
            return;
        setLoading(true);
        try {
            const {data} = await axios.post(Api.publisher.collections.collections, {
                title: name,
                language_locale: locale?.key || locale
            });
            if (data.success && onCreated) {
                onCreated(data.data);
                toast(t("Collection Added Successfully!"),{type:"success"})
            }
        } catch (e) {

        }
        setLoading(false);
    };

    return <ModalWrapper className={"collections-modal"} md toggle={toggleDisplay}>
        <TextInput placeholder={t("Collection Name")} value={name} onChange={(e) => setName(e.target.value)}/>
        <button className={"btn-submit-collection"} onClick={handleCreateCollection}>{loading ?
            <CircleLoading/> : t("Submit")}</button>
    </ModalWrapper>;

};

export default CollectionsModal;
