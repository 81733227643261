import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./css/EmailLogin.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
import TextInput from "../../../common/others/TextInput";
// Form validation
import {Formik} from "formik";
import {emailLoginSchemas} from "../../../../utils/formValidationSchemas";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";

const ForgetPasswordRequest = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {modalsDisplayStatus, toggleModals, setAuthValues, authValues} = useContext(NavbarContext);
    const [loading, setLoading] = useState(false);

    function toggle() {
        toggleModals({...modalsDisplayStatus, forgetPasswordRequest: false});
        setAuthValues({
            ...authValues, resetEmailAddress: '', resetToken: ''
        })
    }

    async function submit(values) {
        if (loading)
            return;
        setAuthValues({
            ...authValues,
            resetEmailAddress: values.emailAddress
        });
        setLoading(true);

        try {

            const {data} = await axios.post(getRouter(API.auth.forgetPassword.request, {
                userType: authValues.userType
            }), {
                email: values.emailAddress,
            });

            if (data.success) {
                if (data.data.code)
                    toast(data.data.code, {type: 'success'})
                toggleModals({...modalsDisplayStatus, forgetPasswordRequest: false, forgetPasswordVerify: true});
            }
        } catch (e) {

        }

        setLoading(false);
    }

    return (
        <ModalWrapper className={"email-login-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Forget Password")}</p>

            <Formik
                initialValues={{emailAddress: authValues.resetEmailAddress}}
                onSubmit={(values) => submit(values)}
            >
                {({
                      errors,
                      values,
                      handleChange,
                      touched,
                      setFieldTouched,
                      handleSubmit,
                  }) => (
                    <>
                        <TextInput
                            placeholder={t("Email address")}
                            value={values.emailAddress}
                            onChange={handleChange("emailAddress")}
                            onBlur={() => setFieldTouched("emailAddress")}
                            error={{
                                message: errors.emailAddress,
                                visible: touched.emailAddress,
                            }}
                            ltr
                        />
                        <button onClick={() => handleSubmit()} type={"button"} className={"button primary"}>
                            {loading
                                ? <CircleLoading/>
                                : t("Confirm")}
                        </button>
                    </>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default ForgetPasswordRequest;
