import React, { useEffect, useState } from "react";
import "./css/MyPublishersAndProfitHeader.scss";
import { useTranslation } from "react-i18next";
// Common components
import DropdownMenu from "../../common/others/DropdownMenu";

const MyPublishersAndProfitHeader = ({
                                       onSortChange = (sort) => {
                                       },
                                       onCurrencyChange = (currency) => {
                                       },
                                       showprices = true,
                                       title,
                                       totalProfit,
                                       withdrawableProfit,
                                       currencies = []
                                     }) => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(
    t("Sort by") + ": " + t("Latest")
  );

  const [currency, setCurrency] = useState(
    t("Currency") + ": " + (currencies.length ? currencies[0].title : "")
  );

  useEffect(() => {
    setCurrency(t("Currency") + ": " + (currencies.length ? currencies[0].title : ""));
  }, [currencies]);


  return (
    <div className={"my-publishers-and-profit-header"}>
      <div>
        {title && <p className={"my-publishers-and-profit-title"}>{title}</p>}
        {showprices ? <p className={"my-publishers-and-profit-month-price"}>
          {t("Withdrawable Profit") +
            ": " +
            withdrawableProfit +
            " " +
            t("Toman")}
        </p> : null}
      </div>

      <div>
        {showprices ? <p>
          {t("Total profit") +
            ": " +
            totalProfit +
            " " +
            t("Toman")}
        </p> : null}
        <DropdownMenu value={sortBy} maxWidth={280}>

          <p onClick={() => {
            setSortBy(t("Sort by") + ": " + t("Latest"));
            onSortChange("desc");
          }}>
            {t("Latest")}
          </p>
          <p onClick={() => {
            setSortBy(t("Sort by") + ": " + t("Oldest"));
            onSortChange("asc");
          }}>
            {t("Oldest")}
          </p>
        </DropdownMenu>
        {currencies.length ? <DropdownMenu value={currency} maxWidth={280}>
          {currencies.map((c, index) => {
            return <p key={index} onClick={() => {
              setCurrency(t("Currency") + ": " + c.title);
              onCurrencyChange(c);
            }}>
              {c.title}
            </p>;
          })}
        </DropdownMenu> : null}
      </div>
    </div>
  );
};

export default MyPublishersAndProfitHeader;
