import React, {useContext, useState} from "react";
import "./css/Comments.scss";
import {useTranslation} from "react-i18next";
// Common components
import NewsAndComment from "../../../common/others/NewsAndComment";
// Modals
import AddCommentModal from "../../../modals/AddComment";
// Utils
import comments from "../../../../utils/fake-data/comments";
import ProductInfoContext from "../../../../utils/contexts/Product";

const Comments = ({comments}) => {
    const {t} = useTranslation();
    const [showAddCommentModal, setShowAddCommentModal] = useState(false);
    const {product} = useContext(ProductInfoContext);

    return (
        <div className={"comments"}>
            <div>
                <p>{t("Comments about audio book")}</p>
                <button
                    type={"button"}
                    className={"button primary"}
                    onClick={() => setShowAddCommentModal(true)}
                >
                    {t("Add comment")}
                </button>
            </div>

            {comments?.map((comment) => (
                <NewsAndComment key={comment.id} comment={comment} isComment replies={comment.replies}/>
            ))}

            {showAddCommentModal && (
                <AddCommentModal productId={product?.id} toggleDisplay={setShowAddCommentModal}/>
            )}
        </div>
    );
};

export default Comments;
