import "./css/Reader.scss";
import {useEffect, useRef, useState} from "react";
import {
    EpubView, EpubViewStyle
} from "./../../components/react-reader";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "./../../utils/http";
import CircleLoading from "../../components/common/others/CircleLoading";
import icons from "../../utils/icons";
import {useClickOutside} from "@mantine/hooks";
import Api from "../../utils/api";
import {getRouter} from "../../utils/helpers";
import {toast} from "react-toastify";
import ModalWrapper from "../../components/common/others/ModalWrapper";
import TextInput from "../../components/common/others/TextInput";
import DropdownMenu from "../../components/common/others/DropdownMenu";
import queryString from "query-string";
import {Document, Page, pdfjs} from "react-pdf";
import localforage from "localforage";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Reader = () => {
    const [url, setUrl] = useState("");
    const client = useSelector(state => state.client);
    const loc = useLocation();
    const readerSidebar = useClickOutside(() => setSidebarOpen(false));
    const renderTools = useClickOutside(() => setShowTools(false));
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [fileData, setFileData] = useState();
    const [tocs, setTocs] = useState([]);
    const [location, setLocation] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarTab, setSidebarTab] = useState("category");
    const renditionRef = useRef();
    const readerContainer = useRef();
    const [theme, setTheme] = useState("light");
    const [themes, setThemes] = useState({
        light: {
            body: {
                background: "#fff"
            }, "span": {
                color: "#000 !important"
            }, "p": {
                color: "#000 !important"
            }, "li": {
                color: "#000 !important"
            }, "h1": {
                color: "#000 !important"
            }, "a": {
                "color": "#000 !important", "pointer-events": "auto !important", "cursor": "pointer !important"
            }, "::selection": {
                background: "lightskyblue !important"
            }
        }, classic: {
            "body": {
                background: "#F1E9DE"
            }, "span": {
                color: "#42312A !important"
            }, "p": {
                color: "#362016 !important"
            }, "li": {
                color: "#42312A !important"
            }, "h1": {
                color: "#42312A !important"
            }, "a": {
                "color": "#009DAE !important", "pointer-events": "auto !important", "cursor": "pointer !important"
            }, "::selection": {
                background: "lightskyblue !important"
            }
        }, dark: {
            "body": {
                background: "#333"
            }, "span": {
                color: "#fff !important"
            }, "p": {
                color: "#fff !important"
            }, "li": {
                color: "#fff !important"
            }, "h1": {
                color: "#fff !important"
            }, "a": {
                "color": "#fff !important", "pointer-events": "auto !important", "cursor": "pointer !important"
            }, "::selection": {
                background: "lightskyblue !important"
            }
        }
    });
    const [showHeader, setShowHeader] = useState(true);
    const [size, setSize] = useState(15);
    const [page, setPage] = useState(1);
    const [title, setTitle] = useState("");
    const [pageInfo, setPageInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const [epubCfiRange, setEpubCfiRange] = useState(false);
    const [iframeContent, setIframeContent] = useState(false);
    const [highlights, setHighlights] = useState([]);
    const [notes, setNotes] = useState([]);
    const [noteModal, setNoteModal] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const [maxPdfPages, setMaxPdfPages] = useState(1);
    const [pdfPage, setPdfPage] = useState(1);
    const [pdfLoading, setPdfLoading] = useState(true);
    const [note, setNote] = useState("");
    const [selectedNote, setSelectedNote] = useState(null);
    const [selectedFont, setSelectedFont] = useState("Default");
    const [font, setFont] = useState("sans-serif");
    const [pageLocations, setPageLocations] = useState(null);
    const [error, setError] = useState(false);
    const [searchTitle, setSearchTitle] = useState("");
    const [searchItems, setSearchItems] = useState([]);
    const [readerHeight, setReaderHeight] = useState(window.innerHeight - 52);
    const fonts = [
        {
            font: "sans-serif",
            label: "Default"
        },
        {
            font: "IRANSans",
            label: "Iran Sans"
        },
        {
            font: "cursive",
            label: "Cursive"
        }
    ];
    const timeId = useRef();
    const readerTimeId = useRef();

    useEffect(() => {
        // if (!client) {
        //   toast(t("Authentication Failed! Please Login Again"), { type: "error" });
        //   navigate("/");
        //   return;
        // }

        const pageInfoData = loc.state || queryString.parse(loc.search);
        if (pageInfoData.token) {
            axios.setAuthorizationHeader(pageInfoData.token);
        }
        let isPdfReader = false;
        if (pageInfoData?.url?.match(/\.pdf/i)) {
            isPdfReader = true;
            setIsPdf(true);
        }
        setTitle(pageInfoData?.title);
        setPageInfo(pageInfoData);
        if (!pageInfoData?.url) return navigate(-1);

        if (!isPdfReader) {
            const localTheme = localStorage.getItem("reader-theme");
            const localFont = fonts.find(f => f.font === localStorage.getItem("reader-font"));
            const localFontSize = +localStorage.getItem("reader-font-size");
            setTheme(localTheme !== "light" && localTheme !== "classic" && localTheme !== "dark" ? "light" : localTheme);
            setFont(localFont ? localFont.font : "sans-serif");
            setSelectedFont(localFont ? localFont.label : "Default");
            if (localFontSize && !isNaN(localFontSize)) {
                if (localFontSize < 9) {
                    setSize(9);
                } else if (size > 25) {
                    setSize(25);
                } else {
                    setSize(localFontSize);
                }
            } else {
                setSize(15);
            }
            initializeReaderData(pageInfoData);
        }

        // document.addEventListener('click',(e)=>{
        //   setShowTools(false)
        // })

        const sidebar = document.querySelector("#reader-sidebar");

        if (sidebar) {
            sidebar.addEventListener("copy", (e) => {
                e.preventDefault();
            });

            sidebar.addEventListener("dragstart", (e) => {
                e.preventDefault();
            });
        }

        return () => {
            clearTimeout(timeId.current);
            clearTimeout(readerTimeId.current);
        };
    }, []);

    useEffect(() => {
        if (!pageLocations && renditionRef?.current?.book) {
            renditionRef.current.book.locations.generate(1000).then(res => console.log("*************", res));

            // renditionRef.current.book.locations.generate().then(res => setPageLocations(res))
        }
    }, [renditionRef.current]);

    useEffect(() => {
        const viewportMeta = document.querySelector("meta[name='viewport']");
        const timeoutId = setTimeout(() => {
            viewportMeta.setAttribute("content", `width=device-width, height=${window.innerHeight}px, initial-scale=1, maximum-scale=1, user-scalable=0`);
            setReaderHeight(window.innerHeight - 104)
        }, 500);
        return () => {
            clearTimeout(timeoutId);
            viewportMeta.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0");
        };
    }, [isFullScreen]);

    const initializeReaderData = async (pageInfoData) => {
        if (client || pageInfoData?.token) {
            await getHighlights();
            await getNotes();
        }
        await getEPubData();
    };

    function getSelectionText(currentWindow) {
        let text = "";
        if (currentWindow.getSelection) {
            text = currentWindow.getSelection().toString();
        } else if (currentWindow.document.selection && currentWindow.document.selection.type != "Control") {
            text = currentWindow.document.selection.createRange().text;
        }
        return text;
    }

    const storeReadBookReport = () => {
        if ((!client || client?.role != "user") && !pageInfo.token) return;
        const timeoutId = setInterval(async () => {
            if (!navigator.onLine) {
                clearTimeout(timeId.current);
                return;
            }
            try {
                await axios.post(getRouter(Api.dashboard.readBook, {
                    product: pageInfo?.slug
                }), {
                    product_type: pageInfo?.productType, product_lang: pageInfo?.locale, time: 5
                });
            } catch (e) {
                clearTimeout(timeId.current);
            }
        }, 5000);
        timeId.current = timeoutId;
    };
    const storeLatestBookLocation = (loc) => {
        if ((!client || client?.role != "user") && !pageInfo.token) return;
        const timeoutId = setInterval(async () => {
            if (!navigator.onLine) {
                clearTimeout(readerTimeId.current);
                return;
            }
            if (loc) {
                try {
                    await axios.post(getRouter(Api.dashboard.lastReadLocationBook, {
                        product: pageInfo?.slug
                    }), {
                        product_type: pageInfo?.productType,
                        product_lang: pageInfo?.locale,
                        value: loc ? renditionRef.current.book.locations.percentageFromCfi(loc) : 0,
                        location: loc || ""
                    });
                } catch (e) {
                    clearTimeout(readerTimeId.current);
                }
            }

        }, 5000);
        readerTimeId.current = timeoutId;
    };

    // useEffect(() => {
    //   if (renditionRef.current) {
    //     renditionRef.current.themes.fontSize(`${size}px`);
    //   }
    // }, [size]);

    const getEPubData = async () => {
        const pageInfo = loc.state || queryString.parse(loc.search);
        try {
            if (!navigator.onLine) {
                let localData = await localforage.getItem(pageInfo.slug);
                if (localData) {
                    localData = JSON.parse(localData);
                    setPageInfo({...pageInfo, lastPage: pageInfo?.lastPage || localData?.lastPage});
                    setFileData(localData.blob);
                }
                return;
            }
        } catch (e) {
            return navigate(-1);
        }

        try {
            const {data} = await axios.defaultAxios.get(pageInfo?.url);
            try {
                await localforage.setItem(pageInfo.slug, JSON.stringify({
                    blob: data,
                    lastPage: pageInfo.lastPage
                }));
            } catch (e) {
                console.error(e);
            }
            setFileData(data);
        } catch (e) {
            if (e?.response?.status === 404) {
                toast.error('خطایی در خواندن فایل رخ داد یا فایل یافت نشد')
                setError(true)
            } else {
                let localData = await localforage.getItem(pageInfo.slug);
                if (localData) {
                    localData = JSON.parse(localData);
                    setPageInfo({...pageInfo, lastPage: pageInfo?.lastPage || localData?.lastPage});
                    setFileData(localData.blob);
                }
            }
        }
    };

    const getHighlights = async () => {
        if (!navigator.onLine)
            return;
        try {
            const pageInfo = loc.state || queryString.parse(loc.search);
            const {data} = await axios.get(`/products/${pageInfo.slug}/highlights`);
            setHighlights(data.data);
        } catch (e) {

        }
    };

    const getNotes = async () => {
        if (!navigator.onLine)
            return;
        try {
            const pageInfo = loc.state || queryString.parse(loc.search);
            const {data} = await axios.get(`/products/${pageInfo.slug}/notes`);
            setNotes(data.data);
        } catch (e) {

        }
    };

    // const ownStyles = {
    //     ...EpubViewStyle, arrow: {
    //         ...EpubViewStyle.arrow, display: "none", color: "red"
    //     }, container: {
    //         ...EpubViewStyle.container, height: `${readerHeight}px`
    //     }, readerArea: {
    //         ...ReactReaderStyle.readerArea, backgroundColor: "transparent", height: `${readerHeight}px`
    //     }, reader: {
    //         ...ReactReaderStyle.reader, inset: "10px 10vw"
    //     }
    // };

    const handleFullScreenMode = () => {
        const contentContainer = document.querySelector(".reader-container");
        if (!contentContainer) return;
        if (document.fullscreenElement) {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        } else {
            const requestFullScreen = contentContainer?.requestFullscreen() || contentContainer?.webkitRequestFullscreen() || contentContainer?.mozRequestFullScreen() || contentContainer?.msRequestFullscreen();
            requestFullScreen().then(() => {
                setIsFullScreen(true)
            });
        }
    };

    const initFeatures = () => {
        const iframe = document.getElementsByTagName("iframe")?.[0];
        if (!iframe) return;

        iframe.contentWindow.document.addEventListener('selectionchange', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        iframe.contentWindow.document.addEventListener("copy", (e) => {
            e.preventDefault();
        });

        iframe.contentWindow.document.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });

        // iframe.contentWindow.document.addEventListener("selectstart", (e) => {
        //   e.preventDefault();
        //   console.log("selectstart");
        // });

        // iframe.contentWindow.document.addEventListener("mouseup", () => {
        //   const selectedText = getSelectionText(iframe.contentWindow);
        //   console.log(selectedText);
        // });
        //
        iframe.contentWindow.document.addEventListener("touchstart", () => {
            console.log("touchstart");
        });
        iframe.contentWindow.document.addEventListener("touchend", () => {
            console.log("touchend");
            const selectedText = getSelectionText(iframe.contentWindow);
            console.log(selectedText);
        });

        iframe.contentWindow.document.addEventListener("dragstart", (e) => {
            e.preventDefault();
        });

    };

    const addHighlight = async () => {
        // setLocation('epubcfi(/6/4!/4/2,/6/1:281,/8/1:19)');
        setLoading(true);
        try {
            const text = getSelectionText(iframeContent.window);
            const {data} = await axios.post(`/products/${pageInfo.slug}/highlights`, {
                text, location: epubCfiRange
            });
            if (data.success) {
                renditionRef.current.annotations.highlight(epubCfiRange, {}, (e) => {
                    console.log("highlight clicked", e.target);
                });
                setHighlights([...highlights, {
                    id: data.data.id, text, location: epubCfiRange
                }]);
                setShowTools(false);
                iframeContent.window.getSelection().removeAllRanges();
            }
        } catch (e) {

        }
        setLoading(false);
    };

    const removeHighlight = async (highlight) => {
        setLoading(true);
        try {
            const {data} = await axios.delete(`/products/${pageInfo.slug}/highlights/${highlight.id}`);
            if (data.success) {
                renditionRef.current.annotations.remove(highlight.location, "highlight");
                setHighlights(highlights.filter(h => h.id !== highlight.id));
            }
        } catch (e) {

        }
        setLoading(false);
    };

    const addEditNote = async () => {
        setLoading(true);
        try {
            if (selectedNote) {
                const {data} = await axios.put(`/products/${pageInfo.slug}/notes/${selectedNote.id}`, {
                    text: selectedNote.text, note, location: selectedNote.location
                });
                if (data.success) {
                    setNotes(notes.map(n => n.id === selectedNote.id ? {
                        ...selectedNote, text: selectedNote.text, note, location: selectedNote.location
                    } : n));
                    setShowTools(false);
                    setNote("");
                    setNoteModal(false);
                    setSelectedNote(null);
                }
            } else {
                const text = getSelectionText(iframeContent.window);
                const {data} = await axios.post(`/products/${pageInfo.slug}/notes`, {
                    text, note, location: epubCfiRange
                });
                if (data.success) {
                    renditionRef.current.annotations.highlight(epubCfiRange, {}, (e) => {
                        console.log("note clicked", e.target);
                    }, "", {
                        fill: "blue"
                    });
                    setNotes([...notes, {
                        id: data.data.id, text, note, location: epubCfiRange
                    }]);
                    setShowTools(false);
                    setNote("");
                    setNoteModal(false);
                    setSelectedNote(null);
                    iframeContent.window.getSelection().removeAllRanges();
                }
            }
        } catch (e) {

        }
        setLoading(false);
    };

    const removeNote = async (note) => {
        setLoading(true);
        try {
            const {data} = await axios.delete(`/products/${pageInfo.slug}/notes/${note.id}`);
            if (data.success) {
                renditionRef.current.annotations.remove(note.location, "highlight");
                setNotes(notes.filter(n => n.id !== note.id));
            }
        } catch (e) {
        }
        setLoading(false);
    };

    const shareSelection = async () => {
        const text = getSelectionText(iframeContent.window);

        if (text.length > 600) {
            toast(t("Text Must be Less Than 600 Character!"), {type: "error"});
            return;
        }

        if (!navigator?.clipboard) return;
        try {
            await navigator.clipboard.writeText(`${text}\r\n\r\nhttps://anubani.com`);
            toast(t("Text Copied!"), {type: "success"});
        } catch (e) {

        }

        if (!navigator.share)
            return;
        try {
            await navigator.share({
                title: pageInfo.title,
                text
            });
        } catch (err) {
        }
    };

    const onDocumentLoadSuccess = (e) => {
        setPdfLoading(false);
        setMaxPdfPages(e.numPages);
        const pdfPage = document.querySelector(".react-pdf__Page");
        if (!pdfPage) return;

        pdfPage.addEventListener("copy", (e) => {
            e.preventDefault();
        });

        pdfPage.addEventListener("dragstart", (e) => {
            e.preventDefault();
        });
        console.log("success: ", e);
    };

    const onLoadError = (error) => {
        console.log(error);
    };

    const searchInBook = (q) => {
        if (!renditionRef.current)
            return;

        const book = renditionRef.current.book;

        return Promise.all(
            book.spine.spineItems.map(item => item.load(book.load.bind(book)).then(item.find.bind(item, q)).finally(item.unload.bind(item)))
        ).then(results => Promise.resolve([].concat.apply([], results)));
    };

    const handleSearch = async () => {
        if (searchTitle === "") {
            setSearchItems([]);
            return;
        }
        if (searchTitle.length < 3) {
            setSearchItems([]);
            toast(t("Enter at least 3 Characters"), {type: "error"});
            return;
        }
        const res = await searchInBook(searchTitle);
        setSearchItems(res);
    };

    return <>
        <div className={"reader-container"} style={{background: themes[theme].body.background}}>
            <div className={`reader-navbar ${showHeader ? "show" : ""} ${theme}`} style={{position: 'relative'}}>
                <button className={isPdf ? "disabled-featured" : ""}
                        onClick={() => setSidebarOpen(true)}>{icons.hamburger}</button>
                <button
                    onClick={handleFullScreenMode}>{isFullScreen ? icons.exitFullScreen : icons.enterFullScreen}</button>
                <p className={"book-title"}>{title}</p>

                <button className={"btn-exit"} onClick={() => navigate(-1)}>
                    {icons.exit}
                </button>
            </div>
            {!isPdf ? <div id={`reader-sidebar`} ref={readerSidebar}
                           className={`reader-sidebar ${sidebarOpen ? "reader-sidebar-open" : ""} ${theme}`}>
                <div className={"reader-sidebar-header"}>
                    <p className={"reader-sidebar-title"}>{t("List and Settings")}</p>
                    <button className={"btn-close-reader-sidebar"}
                            onClick={() => setSidebarOpen(false)}>{icons.cross}</button>
                </div>
                <div className={"reader-sidebar-body"}>
                    <div style={{
                        padding: "10px 8px"
                    }}>
                        <TextInput value={searchTitle} searchable onSearchClick={handleSearch} placeholder={t("Search")}
                                   onChange={(e) => {
                                       if (e.target.value === "") {
                                           setSearchItems([]);
                                       }
                                       setSearchTitle(e.target.value);
                                   }}/>
                    </div>
                    <div className={"sidebar-tabs"}>
                        <button className={`${sidebarTab === "category" ? "selected-tab" : ""}`}
                                onClick={() => setSidebarTab("category")}>
                            {icons.content}
                        </button>
                        <button className={`${sidebarTab === "highlight" ? "selected-tab" : ""}`}
                                onClick={() => setSidebarTab("highlight")}>
                            {icons.highlight}
                        </button>
                        <button className={`${sidebarTab === "note" ? "selected-tab" : ""}`}
                                onClick={() => setSidebarTab("note")}>
                            {icons.note}
                        </button>
                        <button className={`${sidebarTab === "search" ? "selected-tab" : ""}`}
                                onClick={() => setSidebarTab("search")}>
                            {icons.search}
                        </button>
                    </div>
                    {sidebarTab === "category" ? <ul className={"toc-list"}>
                        {tocs.length ? tocs.map((t, i) => <li key={i}>
                            <button className={"btn-toc"}
                                    onClick={() => {
                                        setLocation(t.href);
                                    }}>
                                {t.label.trim()}
                            </button>
                        </li>) : <li className={"empty-list"}><p>{t("No Contents Found")}</p></li>}
                    </ul> : null}
                    {sidebarTab === "highlight" ? <ul className={"highlights-list"}>
                        {highlights.length ? highlights.map((h, i) => <li key={i}>
                            <div className={"highlight-item"}>
                                <pre onClick={() => setLocation(h.location)}>{h.text}</pre>
                                <div>
                                    <button type="button" onClick={() => removeHighlight(h)}>{icons.delete}</button>
                                </div>
                            </div>
                        </li>) : <li className={"empty-list"}><p>{t("No Highlight Found")}</p></li>}
                    </ul> : null}
                    {sidebarTab === "note" ? <ul className={"notes-list"}>
                        {notes.length ? notes.map((n, i) => <li key={i}>
                            <div className={"note-item"}>
                                <pre onClick={() => setLocation(n.location)}>{n.text}</pre>
                                <hr/>
                                <p>{n.note}</p>
                                <div>
                                    <button type="button" onClick={() => {
                                        setSelectedNote(n);
                                        setNote(n.note);
                                        setNoteModal(true);
                                    }}>{icons.edit}</button>
                                    <button type="button" onClick={() => removeNote(n)}>{icons.delete}</button>
                                </div>
                            </div>
                        </li>) : <li className={"empty-list"}><p>{t("No Notes Found")}</p></li>}
                    </ul> : null}
                    {sidebarTab === "search" ? <ul className={"search-list"}>
                        {searchItems.length ? searchItems.map((s, i) => <li key={i}>
                            <div className={"search-item"}>
                                <pre onClick={() => setLocation(s.cfi)}>{s.excerpt}</pre>
                            </div>
                        </li>) : <li className={"empty-list"}><p>{t("No Items Found")}</p></li>}
                    </ul> : null}
                    <p className={"reader-themes-title"}>{t("Select Font")}</p>
                    <div className="reader-font">
                        <DropdownMenu value={selectedFont}>
                            {fonts.map((f) => <p onClick={() => {
                                setSelectedFont(f.label);
                                setFont(f.font);
                                localStorage.setItem("reader-font", f.font);
                                // renditionRef.current.themes.font(f.font);
                            }}>{f.label}</p>)}
                        </DropdownMenu>
                    </div>
                    <p className={"reader-themes-title"}>{t("Select Font Size")}</p>
                    <div className="reader-font-size">
                        <button onClick={() => {
                            if (size < 25) {
                                localStorage.setItem("reader-font-size", size + 1);
                                setSize(size + 1);
                                renditionRef.current.themes.fontSize(`${size + 1}px`);
                            }
                        }}>
                            {icons.plus}
                        </button>
                        <p className={"reader-font-size-value"}>{size}</p>
                        <button onClick={() => {
                            if (size > 9) {
                                localStorage.setItem("reader-font-size", size - 1);
                                renditionRef.current.themes.fontSize(`${size - 1}px`);
                                setSize(size - 1);
                            }
                        }}>
                            {icons.minus}
                        </button>
                    </div>
                    <p className={"reader-themes-title"}>{t("Select Color")}</p>
                    <div className="reader-themes">
                        <button style={{background: "#fff", borderColor: "#000"}}
                                onClick={() => {
                                    localStorage.setItem("reader-theme", "light");
                                    window.postMessage(JSON.stringify(themes.light));
                                    setTheme("light");
                                }}></button>
                        <button style={{background: "#F1E9DE", borderColor: "#42312A"}}
                                onClick={() => {
                                    localStorage.setItem("reader-theme", "classic");
                                    window.postMessage(JSON.stringify(themes.classic));
                                    setTheme("classic");
                                }}></button>
                        <button style={{background: "#333", borderColor: "#000"}}
                                onClick={() => {
                                    localStorage.setItem("reader-theme", "dark");
                                    window.postMessage(JSON.stringify(themes.dark));
                                    setTheme("dark");
                                }}></button>
                    </div>
                </div>
            </div> : null}
            <div ref={readerContainer} onClick={() => {
                console.log("container clicked");
                setShowHeader(!showHeader);
            }}>
                {isPdf ? <>
                    <div className={"pdf-container"}>
                        {pdfLoading ? <CircleLoading color={"#1c3c53"} width={200} height={200}/> : null}

                        <Document file={`${pageInfo?.url}?download=1`}
                                  onLoadSuccess={onDocumentLoadSuccess} onLoadError={onLoadError} loading={""}>
                            <Page pageNumber={pdfPage}/>
                        </Document>
                    </div>
                </> : (!error ?
                    <EpubView
                        key={theme + selectedFont + readerHeight}
                        showToc={false}
                        swipeable={false}
                        url={fileData}
                        epubInitOptions={{
                            encoding: "base64"
                        }}
                        epubOptions={{
                            spread: "none",
                            width: "100%",
                            height: "calc(100vh - 120px)",
                            ignoreClass: 'annotator-hl',
                            manager: "continuous"
                        }}
                        getRendition={(rendition) => {
                            console.log("rendered", rendition);
                            for (const highlight of highlights) {
                                rendition.annotations.highlight(highlight.location);
                            }

                            for (const note of notes) {
                                rendition.annotations.highlight(note.location, {}, () => {
                                }, "", {
                                    fill: "blue"
                                });
                            }
                            rendition.themes.fontSize(`${size}px`);
                            rendition.themes.font(font);
                            rendition.on("selected", function (cfiRange, contents) {
                                console.log("range", cfiRange);
                                console.log("contents", contents);
                                setShowTools(true);
                                setEpubCfiRange(cfiRange);
                                setIframeContent(contents);
                            });
                            rendition.on("mousedown", () => {
                                setShowTools(false);
                                setSidebarOpen(false);

                            });
                            rendition.on("click", () => {
                                console.log("rendition clicked");
                                setShowHeader(pre => {
                                    return !pre;
                                });
                            });
                            rendition.themes.register("light", themes.light);
                            rendition.themes.register("classic", themes.classic);
                            rendition.themes.register("dark", themes.dark);
                            if (theme === "light") rendition.themes.select("light"); else if (theme === "classic") rendition.themes.select("classic"); else rendition.themes.select("dark");
                            renditionRef.current = rendition;
                            clearTimeout(timeId.current);
                            if (pageInfo?.purchaseType !== "demo") {
                                storeReadBookReport();
                            }
                        }}
                        loadingView={<CircleLoading color={"#1c3c53"} width={200} height={200}/>}
                        tocChanged={(toc) => setTocs(toc)}
                        location={location}
                        locationChanged={(l) => {
                            initFeatures();
                            if (pageInfo.lastPage) {
                                setLocation(pageInfo.lastPage);
                                setPageInfo(prev => ({
                                    ...pageInfo,
                                    lastPage: null
                                }));
                            }

                            clearTimeout(readerTimeId.current);
                            if (pageInfo?.purchaseType !== "demo") {
                                storeLatestBookLocation(l);
                            }

                            setLocation(prev => l);

                            if (renditionRef.current) {
                                const {displayed} = renditionRef.current?.location.start;
                                setPage(prev => displayed?.page);
                            }
                        }}
                        // styles={ownStyles}
                    />
                    : null)
                }
            </div>
            {!isPdf ? <div className={`render-tools ${showTools ? "show" : ""} ${theme}`} ref={renderTools}>
                <button type="button" onClick={addHighlight}>
                    {icons.highlight}
                    <span>{t("Add Highlight")}</span>
                </button>
                <button type="button" onClick={() => setNoteModal(true)}>
                    {icons.note}
                    <span>{t("Add Note")}</span>
                </button>
                <button type="button" onClick={shareSelection}>
                    {icons.share}
                    <span>{t("Share")}</span>
                </button>
            </div> : null}
            <div className={`reader-footer ${theme}`}>
                <button className={"btn-page-navigation"}
                        onClick={() => {
                            console.log(renditionRef.current);
                            if (isPdf) {
                                if (pdfPage >= maxPdfPages) {
                                    return;
                                }
                                setPdfPage(pdfPage + 1);
                            } else {
                                // setPage(page + 1)
                                renditionRef.current.next();
                            }
                        }}>{icons.arrowhead}</button>
                <p className={"page-number"}
                   style={{direction: "ltr"}}>{isPdf ? `${pdfPage} / ${maxPdfPages}` : page}</p>
                <button className={"btn-page-navigation"}
                        onClick={() => {
                            if (isPdf) {
                                if (pdfPage <= 1) {
                                    return;
                                }
                                setPdfPage(pdfPage - 1);
                            } else {
                                // setPage(page - 1)
                                renditionRef.current.prev();
                            }
                        }}>{icons.arrowhead}</button>
            </div>
            {noteModal ? <ModalWrapper toggle={() => {
                setNote("");
                setSelectedNote(null);
                setNoteModal(false);
            }}>
                <div className={"note-modal"}>
                    <TextInput value={note} onChange={(e) => setNote(e.target.value)} placeholder={"Note"}/>
                    <button className={"button primary"} onClick={addEditNote}>
                        {selectedNote ? "Edit Note" : "Add Note"}
                    </button>
                </div>
            </ModalWrapper> : null}
            {loading ? <ModalWrapper xs>
                <CircleLoading width={150} height={150} color={"#1c3c53"}/>
            </ModalWrapper> : null}
        </div>
    </>;
};

export default Reader;
