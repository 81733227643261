import {useContext, useEffect, useState} from "react";
import "./ProductInfo.scss";
// Common components
import Card from "../../../common/others/Card";
// Sub components
import Cover from "./sub-components/Cover";
import Details from "./sub-components/Details";
import PurchaseAndPreview from "./sub-components/PurchaseAndPreview";
import Footer from "./sub-components/Footer";
// Utils
import icons from "../../../../utils/icons";
import ProductInfoContext from "../../../../utils/contexts/Product";
import axios from "../../../../utils/http";
import Api from "../../../../utils/api";
import {getRouter} from "../../../../utils/helpers";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderButtons from "./common/SliderButtons";
import { Autoplay } from "swiper";


const ProductInfo = ({ data, packageType }) => { 
    const [mobileView, setMobileView] = useState();
    useEffect(() => {
        console.log(mobileView);
    }, [mobileView])
    const {product, onLanguageChange} = useContext(ProductInfoContext);
    const {t} = useTranslation();
    const [bookmarkCount, setBookmarkCount] = useState(0);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const client = useSelector(state => state.client);

    useEffect(() => {
        setBookmarkCount(product?.bookmarks_count || 0);
        setIsBookmarked(!!product?.is_bookmarked);
    }, [product])

    const bookmarkProduct = async () => {
        try {
            const {data} = await axios.post(getRouter(Api.bookmarks.removeFromBookmark, {
                productId: product.id
            }));

            if (data.success) {
                if (isBookmarked) {
                    setBookmarkCount(bookmarkCount > 0 ? bookmarkCount - 1 : 0)
                } else {
                    setBookmarkCount(bookmarkCount + 1)
                }
                setIsBookmarked(!isBookmarked);
            }
        } catch (e) {

        }
    }

    const copyShareLink = async () => {
        if (!navigator?.clipboard)
            return;
        try {
            await navigator.clipboard.writeText(product?.share_url)
            toast(t("Link Copied!"), {type: "success"})
        } catch (e) {

        }
    }

    const shareLink = () => {
        const data = {
            title: product?.title,
            url: product?.share_url
        };

        if (navigator?.canShare && navigator?.canShare(data)) {
            navigator.share(data);
        }
    }

    return (
        <Card className={`product-info ${product?.type === "podcast" ? "podcast" : ""}`}>
            <div className={i18n.language === 'en' ? 'product-like-share-right' : ''}>
                <div onClick={() => {
                    copyShareLink();
                    shareLink();

                }}>{icons.share}</div>
                {packageType ? null : <div style={{ direction: "ltr" }}
                    className={isBookmarked ? 'bookmarked' : ''}
                    onClick={() => (client && client.role === 'user') ? bookmarkProduct(product) : null}>{icons.heart}
                    <span
                        style={{ margin: "0 5px" }}>{bookmarkCount}</span></div>}
            </div>

            <div className="">
                {packageType == true ? <>
                    {data.covers.length > 2 ? <>
                        <Swiper
                            dir={i18n.language !== "en" ? "rtl" : "ltr"}
                            modules={[Autoplay]}
                            pagination={false}
                            navigation={false}
                            loop={true}
                            spaceBetween={16}
                            slidesPerView={1}
                            autoplay={{
                                delay: 3000,
                                pauseOnMouseEnter: true,
                            }}
                        >
                            {data.covers.map((slide, index) => (
                                <SwiperSlide style={{ alignSelf: 'flex-end' }} key={index}>
                                    <div className={`home-intro-slider ${i18n.language === "en" && "ltr"}`}>
                                        <img alt={"Slide"} className={'slider-image'}
                                            src={slide} />

                                    </div>
                                </SwiperSlide>
                            ))}
                            <SliderButtons />
                        </Swiper>
                    </> : <div class="grid grid-cols-2 gap-4 w-1/2">
                        {data.covers.map(i => <div key={i.id}>
                            <img
                                className="rounded-xl"
                                alt={"Cover"}
                                src={i}
                            />
                        </div>)}
                    </div>}</> : <Cover type={product?.type} cover={product?.cover} language={product?.current_language}
                    languages={product?.languages}
                    onLanguageChange={onLanguageChange} />}
                {packageType == true ?
                <div>
                        <Details product={product} data={data} packageType={true} />
                        <PurchaseAndPreview product={product} data={data} packageType={true} />
                    </div> : <div>
                        <Details product={product} packageType={false} />
                        <PurchaseAndPreview product={product} data={data} packageType={false} />
                    </div>}
            </div>
            {packageType == true ? <Footer product={product} data={data} packageType={true} /> :
                <Footer product={product} data={data} packageType={false} />}
        </Card>
    );
};

export default ProductInfo;
