import React, { useState } from "react";
import "./css/NewsAndComment.scss";
import { useTranslation } from "react-i18next";
// Modal
import ReplyModal from "../../modals/Reply";
// Utils
import icons from "../../../utils/icons";
import tempMedia from "../../../utils/tempMedia";
import axios from "../../../utils/http";
import Api from "../../../utils/api";
import { getRouter } from "../../../utils/helpers";
import { toast } from "react-toastify";

const NewsAndComment = ({ isComment, comment, isReply }) => {
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [likesCount, setLikesCount] = useState(comment.likeCount);
  const [dislikesCount, setDislikesCount] = useState(comment.dislikeCount);
  const [like, setLike] = useState(comment.liked);
  const [dislike, setDislike] = useState(comment.disliked);

  const handleSendVote = async (vote) => {
    try {
      const { data } = await axios.post(getRouter(Api.comments.vote, {
        commentId: comment?.id
      }), {
        like: vote
      });

      if (data.success) {
        if (like && vote) {
          setLike(false);
          setLikesCount(likesCount - 1);
        } else if (dislike && !vote) {
          setDislike(false);
          setDislikesCount(dislikesCount - 1);
        } else if (vote && dislike) {
          setLike(true);
          setDislike(false);
          setLikesCount(likesCount + 1);
          setDislikesCount(dislikesCount - 1);
        } else if (!vote && like) {
          setLike(false);
          setDislike(true);
          setDislikesCount(dislikesCount + 1);
          setLikesCount(likesCount - 1);
        } else if (vote) {
          setLike(true);
          setLikesCount(likesCount + 1);
        } else {
          setDislike(true);
          setDislikesCount(dislikesCount + 1);
        }
      }
    } catch (e) {

    }
  };

  return (
    <>
      <div className={`news-and-comment ${isReply && "reply"}`}>
        <div>
          <div>
            <img alt={"Writer"} src={tempMedia.others.w} />
            <div>
              <p>{comment.name}</p>
              <p></p>
            </div>
          </div>
          <div>
            {icons.clock}
            <p>{comment.time_age}</p>
          </div>
        </div>
        <p className={"title"}>{comment.title}</p>
        <p className={"content"}>{comment.comment}</p>

        {isComment && (
          <div className={"reply-and-rate-section"}>
            <div>
              {icons.reply}
              <p onClick={() => setShowReplyModal(true)}>پاسخ دادن</p>
            </div>
            <div>
              <div onClick={() => handleSendVote(1)}
                   style={{ filter: like ? "grayscale(0)" : "grayscale(1)" }}>
                <p>{likesCount}</p>
                {icons.like}
              </div>
              <div onClick={() => handleSendVote(0)}
                   style={{ filter: dislike ? "grayscale(0)" : "grayscale(1)" }}>
                <p>{dislikesCount}</p>
                {icons.dislike}
              </div>
            </div>
          </div>
        )}
      </div>

      {comment.replies &&
        comment.replies.map((reply, index) => (
          <NewsAndComment
            key={index}
            isComment
            isReply
            comment={reply}
          />
        ))}

      {showReplyModal && <ReplyModal commentId={comment.id} toggleDisplay={setShowReplyModal} name={comment.name} />}
    </>
  );
};

export default NewsAndComment;
