import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// Translations
import {getLocale} from "../utils/helpers";
import faTranslations from "./faTranslations";

i18n.use(initReactI18next).init({
    resources: {
        fa: {
            translation: faTranslations,
        }
    },
    lng: getLocale(),
    fallbackLng: "en",
});
