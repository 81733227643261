import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./Profile.scss";
import "./../../../modals/authentication/others/css/OTP.scss";

// Common components
// import DatePickerInput from "../../../common/others/DatePickerInput";
import RadioButton from "../../../common/others/RadioButton";
import TextInput from "../../../common/others/TextInput";
import PhoneNumberInput from "../../../common/phone-number-input/PhoneNumberInput";
// Sub components
import TextIndicator from "./sub-components/TextIndicator";
// Utils
import images from "../../../../utils/images";
import {useSelector} from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";
import axios from "./../../../../utils/http";
import API from "../../../../utils/api";
import {getRouter} from "../../../../utils/helpers";
import {toast} from "react-toastify";
import icons from "../../../../utils/icons";
import OTPInput from "otp-input-react";
import ModalWrapper from "../../../common/others/ModalWrapper";
import DatePickerInput from "../../../common/others/DatePickerInput";
import moment from "moment-jalaali";

const Profile = () => {
    const {userType} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const client = useSelector(state => state.client);
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nationalID, setNationalID] = useState("");
    const [birthday, setBirthday] = useState(t("Birthday"));
    const [emailAddress, setEmailAddress] = useState("");
    const [gender, setGender] = useState("male");
    const [dialCode, setDialCode] = useState("+98");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [publisherName, setPublisherName] = useState("");
    const [centralOfficeAddress, setCentralOfficeAddress] = useState("");
    const [officeDialCode, setOfficeDialCode] = useState("+98");
    const [officePhoneNumber, setOfficePhoneNumber] = useState("");
    const [poBox, setPoBox] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [officeEmailAddress, setOfficeEmailAddress] = useState("");
    const [shopAddress, setShopAddress] = useState("");
    const [credentials, setCredentials] = useState([]);

    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpType, setOtpType] = useState("");
    const [timer, setTimer] = useState(120);

    useEffect(() => {
        if (!client || (userType !== "user" && userType !== "publisher" && userType !== "marketer")) {
            navigate("404-not-found");
            return null;
        }

        if (client.role === "user") {
            setFirstName(client.first_name || '');
            setLastName(client.last_name || '');
            setEmailAddress(client.email || '');
            setDialCode(`+${client.country_code || 1}`);
            setBirthday(client.birth_date);
            setPhoneNumber(client.mobile || '');
            setGender(client.gender);
        }

    }, [client]);

    let interval = null;

    useEffect(() => {
        interval = setInterval(() => {
            timer > 0 && setTimer((prevState) => prevState - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    function renderTimer() {
        const minutes = Math.floor(timer / 60);
        const minutesToShow = minutes < 10 ? "0" + minutes : minutes;
        const seconds = timer % 60;
        const secondsToShow = seconds < 10 ? "0" + seconds : seconds;
        return minutesToShow + ":" + secondsToShow;
    }

    const resendOTP = async () => {
        if (otpLoading)
            return;

        setOtpLoading(true);
        const putData = {};
        if (otpType === "EMAIL") {
            putData.email = emailAddress;
        } else {
            putData.mobile = phoneNumber;
            putData.country_code = dialCode;
        }
        try {
            const {data} = await axios.put(getRouter(API.auth.profile, {
                userType: userType
            }), putData);

            if (data.success) {
                if (data.data?.code)
                    toast(data.data?.code, {type: "success"});
                setTimer(120);
            }
        } catch (e) {

        }
        setOtpLoading(false);
    };

    const handleSubmit = async (otp) => {
        if (loading)
            return;
        setLoading(true);
        try {
            const {data} = await axios.put(getRouter(API.auth.profile, {userType}), {
                first_name: firstName,
                last_name: lastName,
                email: emailAddress,
                country_code: dialCode.replace("+", ""),
                birth_date: birthday,
                mobile: phoneNumber,
                gender: gender,
                otp: otp && otp.length === 5 ? otp : ""
            });

            if (data.success) {

                if (data.data?.status === "OTP") {
                    if (data?.data?.code)
                        toast(data.data.code, {type: "success"});
                    setShowOtpModal(true);
                    setOtpType(data.data.type);
                    setTimer(120);
                    setOtp("");
                } else {
                    toast(t("Profile Updated Successfully!"), {type: "success"});
                }
            }
        } catch (e) {

        }
        setLoading(false);
    };


    return (
        <>
            <div className={`profile for-${userType}`}>
                <div className={"right-section"}>
                    <TextInput
                        placeholder={t("First name")}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextInput
                        placeholder={t("Last name")}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    {userType !== "user" && (
                        <TextInput
                            placeholder={t("National ID")}
                            value={nationalID}
                            updateValue={(e) => setNationalID(e.target.value)}
                        />
                    )}
                    <DatePickerInput value={birthday ? moment(birthday) : null} placeholder={t("Birth Date")}
                                     onChange={(date) => setBirthday(date)}/>
                    <TextInput
                        placeholder={t("Email address")}
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <div className={"gender-container"}>
                        <RadioButton
                            group={"gender"}
                            id={"male"}
                            title={t("Male")}
                            checked={gender === "male"}
                            updateValue={() => setGender("male")}
                        />
                        <RadioButton
                            group={"gender"}
                            id={"female"}
                            title={t("Female")}
                            checked={gender === "female"}
                            updateValue={() => setGender("female")}
                        />
                    </div>
                    <PhoneNumberInput
                        dialCode={dialCode}
                        onDialCodeChange={setDialCode}
                        phoneNumber={phoneNumber}
                        onPhoneNumberChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {userType === "publisher" && (
                        <>
                            <TextInput
                                placeholder={t("Job title")}
                                value={jobTitle}
                                updateValue={(e) => setJobTitle(e.target.value)}
                            />
                            <TextInput
                                placeholder={t("Publisher name")}
                                value={publisherName}
                                updateValue={(e) => setPublisherName(e.target.value)}
                            />
                        </>
                    )}
                    <button onClick={handleSubmit} type={"button"} className={"button primary"}>
                        {loading ? <CircleLoading/> : t("Confirm")}
                    </button>
                </div>

                {userType !== "user" && <div/>}

                {userType !== "user" && (
                    <div className={"left-section"}>
                        {userType === "publisher" && (
                            <>
                                <div className={"logo"}>
                                    <div>
                                        <button type={"button"}>{t("Upload logo")}</button>
                                        <button type={"button"}>{t("Upload file")}</button>
                                    </div>
                                    <img alt={"Publisher"} src={images.publisherDefaultPhoto}/>
                                </div>
                                <TextInput
                                    placeholder={t("Central office address")}
                                    value={centralOfficeAddress}
                                    updateValue={(e) => setCentralOfficeAddress(e.target.value)}
                                />
                                <PhoneNumberInput
                                    dialCode={officeDialCode}
                                    updateDialCode={setOfficeDialCode}
                                    phoneNumber={officePhoneNumber}
                                    updatePhoneNumber={setOfficePhoneNumber}
                                />
                                <TextInput
                                    placeholder={t("PO box")}
                                    value={poBox}
                                    updateValue={(e) => setPoBox(e.target.value)}
                                />
                                <TextInput
                                    placeholder={t("Postal code")}
                                    value={postalCode}
                                    updateValue={(e) => setPostalCode(e.target.value)}
                                />
                                <TextInput
                                    placeholder={t("Email address")}
                                    value={officeEmailAddress}
                                    updateValue={(e) => setOfficeEmailAddress(e.target.value)}
                                />
                                <TextInput
                                    placeholder={t("Shop address")}
                                    value={shopAddress}
                                    updateValue={(e) => setShopAddress(e.target.value)}
                                />
                            </>
                        )}
                        {userType === "marketeer" && (
                            <>
                                <TextIndicator
                                    title={t("Marketeer code")}
                                    content={"123456789"}
                                    copiable
                                />
                                <TextIndicator title={t("Job title")} content={t("Marketeer")}/>
                                <p>{t("Upload file")}</p>
                                <div className={"images-gallery"}>
                                    {credentials.map((item) => (
                                        <img
                                            key={item}
                                            alt={"Credential"}
                                            src={URL.createObjectURL(item)}
                                        />
                                    ))}
                                </div>
                                <div className={"image-input"}>
                                    <input
                                        type={"file"}
                                        onChange={(e) =>
                                            setCredentials([...credentials, e.target.files[0]])
                                        }
                                    />
                                    <p>{t("Upload photo")}</p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            {showOtpModal && <ModalWrapper className={"otp-modal"} toggle={() => {
                setShowOtpModal(false);
                setOtp("");
                clearInterval(interval);
                setOtpType("");
            }
            }>
                <div>
                    <p style={{textAlign: "center"}}>{otpType === "EMAIL" ? emailAddress : `${dialCode}${phoneNumber}`}</p>
                </div>
                <p
                    style={{textAlign: "center"}}>{otpType === "EMAIL" ? t("Please enter the code sent via Email in the field below.") : t("Please enter the code sent via SMS in the field below.")}</p>
                <OTPInput
                    value={otp}
                    onChange={(value) => {
                        setOtp(value);
                        if (value.length === 5) {
                            setShowOtpModal(false);
                            handleSubmit(value);
                        }
                    }
                    }
                    autoFocus
                    OTPLength={5}
                    otpType="number"
                    className={"otp-input"}
                />
                {timer !== 0 && <div className={"center timer"}>
                    <p>{renderTimer()}</p>
                    {icons.progressRing}
                </div>}
                <p className={"center"}>{t("Remaining time")}</p>
                {timer === 0 && <button type={"button"} className={"button primary"} onClick={resendOTP}>
                    {otpLoading ? <CircleLoading/> : t("Resend")}
                </button>}

            </ModalWrapper>}
        </>
    );
};

export default Profile;
