import React, { useContext } from "react";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Components common between navbar sections
import LanguageSelector from "../common/LanguageSelector";
// Sub components
import SearchBox from "./sub-components/SearchBox";
// Utils
import icons from "../../../../../utils/icons";
import images from "../../../../../utils/images";
import NavbarContext from "../../../../../utils/contexts/Navbar";

const Header = () => {
  const { i18n } = useTranslation();
  const { modalsDisplayStatus, toggleModals } = useContext(NavbarContext);
  function toggleSlideMenu() {
    toggleModals({
      ...modalsDisplayStatus,
      slideMenu: true,
    });
  }

  return (
    <div className={`navbar-header ${i18n.language === "en" ? "ltr" : ''}`}>
      <Link to={"/"}>
        <img alt={"Anobani"} src={images.anobaniLogo}/>
      </Link>

      <div>
        <SearchBox />
        <LanguageSelector />
      </div>

      <button type={"button"} onClick={toggleSlideMenu}>
        {icons.slideMenu}
      </button>
    </div>
  );
};

export default Header;
