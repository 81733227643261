import React, {useEffect, useState} from "react";
import "./css/ShoppingCart.scss";
import {useTranslation} from "react-i18next";
// Common components
import Card from "../../components/common/others/Card";
// Components specific to shopping cart
import CurrencyToggle from "../../components/home-related/shopping-cart/others/CurrencyToggle";
import Product from "../../components/common/others/Product";
import Discount from "../../components/home-related/shopping-cart/others/Discount";
import PaymentMethod from "../../components/home-related/shopping-cart/payment-method/PaymentMethod";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import {getRouter} from "../../utils/helpers";
import {toast} from "react-toastify";
import CircleLoading from "../../components/common/others/CircleLoading";
import {useNavigate} from "react-router-dom";
import instance from "./../../utils/http";
import {useSelector} from "react-redux";
import icons from "../../utils/icons";
// Utils
const ShoppingCart = () => {
    const {t} = useTranslation();
    const client = useSelector(state => state.client);
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState({});
    const [isWallet, setIsWallet] = useState(true);
    const [discount, setDiscount] = useState(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [discountLoading, setDiscountLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!client) {
            toast(t("Authentication Failed! Please Login Again"), {type: 'error'})
            navigate('/');
            return;
        }
        getCartData();
    }, []);

    const getCartData = async (discount = null) => {
        if (loading)
            return;
        if (!discountLoading)
            setLoading(true);
        try {
            const {data} = await axios.get(API.cart.cart, {
                params: {
                    discount
                }
            });

            if (data.success){
                setWallets(data.data.wallets);
                setSelectedWallet(data.data.wallets[0]);
                setProducts(data.data.products);
                setPrices(data.data.prices);
            }else{
                setDiscount('')
            }

        } catch (e) {

        }
        setLoading(false);
        setDiscountLoading(false);
    };
    const removeFromCart = async (product) => {
        if (removeLoading)
            return;
        setRemoveLoading(true);

        try {
            const {data} = await axios.post(getRouter(API.cart.cartAction, {
                productId: product.id
            }), {
                language: product.language,
                type: product.order_type
            });

            if (data.success) {
                toast(data.message, {type: "success"});
                setProducts(products.filter((item) => item.id !== product.id && item.language !== product.language && item.order_type !== product.order_type));
                getCartData(discount);
            }

        } catch (e) {

        }

        setRemoveLoading(false);

    };
    const submitOrder = async () => {
        if (paymentLoading)
            return;
        setPaymentLoading(true);

        try {
            const {data} = await axios.post(API.cart.submitOrder, {
                currency: selectedWallet.currency,
                wallet: +isWallet,
                discount: discount || ""
            });

            if (data.success) {
                if (data.data?.link) {
                    toast(t("Your Request Submitted. You are now Redirecting to Payment Gateway!"), {type: "success"});
                    window.location.href = data.data.link;

                } else {
                    toast(data.message, {type: "success"});
                    navigate("/dashboard/user/purchase-records");
                }
            }

        } catch (e) {

        }

        setPaymentLoading(false);
    };

    if (loading)
        return  <Card className={"shopping-cart"} style={{minHeight:200}}>
            <CircleLoading color={'#1c3c53'} width={150} height={200}/>
        </Card>

    if (!products.length){
        return  <Card className={"shopping-cart"}>
            <div className={'empty-cart'}>
                {icons.shoppingCart}
                <p style={{color:'#000',fontSize:24}}>{t("Empty cart")}</p>
            </div>
        </Card>
    }

    return (
        <Card className={"shopping-cart"}>
            <CurrencyToggle products={products} currencies={wallets} onCurrencyChange={setSelectedWallet}
                            currency={selectedWallet}/>
            <div>
                {products.map((product, index) => (
                    <Product
                        key={index}
                        type={product.order_type}
                        forShoppingCart
                        data={product}
                        price={product.prices?.find(price => price.currency === selectedWallet?.currency)}
                        remove={() => removeFromCart(product)}
                        removeLoading={removeLoading}
                    />
                ))}
            </div>
            <Discount onDiscountCodeSubmit={(discount) => {
                console.log(discount)
                if (!discount)
                    return;
                setDiscountLoading(true);
                setDiscount(discount);
                getCartData(discount);
            }} loading={discountLoading}/>
            {selectedWallet ?
                <PaymentMethod price={prices[selectedWallet?.currency]?.price} selectedWallet={selectedWallet}
                               discount={prices[selectedWallet?.currency]?.discount_price}
                               totalPrice={prices[selectedWallet?.currency]?.total_price}
                               paymentMethod={isWallet}
                               currency={selectedWallet?.currency}
                               onPaymentMethodChanged={setIsWallet}

                />
                : null}
            {products.length ? <button type={"button"} className={"button primary"} onClick={submitOrder}>
                {paymentLoading ? <CircleLoading/> : t("Final payment")}
            </button> : null}
        </Card>
    );
};

export default ShoppingCart;
