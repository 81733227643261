import React, {useEffect, useState} from "react";
import {useClickOutside} from "@mantine/hooks";
import "./css/AmountSelector.scss";
// Utils
import giftCardAmounts from "../../../utils/fake-data/giftCardAmounts";
import i18n from "i18next";

const AmountSelector = ({value, updateValue, values, readOnly = false, className}) => {
    const [showDropdown, setShowDropdown] = useState(null);
    const dropdownRef = useClickOutside(
        () => showDropdown && setShowDropdown(false)
    );
    const dropdownClassName =
        showDropdown === true
            ? "active"
            : showDropdown === false
                ? "inactive"
                : undefined;

    return (
        <div
            className={` rounded-2xl border p-2 text-center border-[#4169E1] font-bold text-[#4169E1] ${className || ''}`}
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropdownRef}
        >

            <div className={dropdownClassName} style={{direction:'rtl'}}>
                {console.log('values', values[1])}
                <p style={{ cursor: readOnly ? 'default' : 'pointer', direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>{values[0].value}</p>
            </div>
        </div>

    );
};

export default AmountSelector;
