import React, { useEffect, useState } from "react";
import "./css/GiftCard.scss";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
// Common components
import Card from "../../components/common/others/Card";
import DatePickerInput from "../../components/common/others/DatePickerInput";
import TextInput from "../../components/common/others/TextInput";
// Components specific to gift card page
import AmountSelector from "../../components/home-related/gift-card/AmountSelector";
import SlideOne from "../../components/home-related/gift-card/SlideOne";
import SlideTwo from "../../components/home-related/gift-card/SlideTwo";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import RadioButton from "../../components/home-related/shopping-cart/payment-method/sub-components/RadioButton";
import CircleLoading from "../../components/common/others/CircleLoading";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { getRouter } from "../../utils/helpers";

const AcceptGiftCard = () => {
  const { i18n, t } = useTranslation();
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [giftData, setGiftData] = useState(null);


  useEffect(() => {
    acceptGiftCode();
  }, []);

  const acceptGiftCode = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(getRouter(API.gift.accept, {
        code
      }));

      if (data.success) {
        setGiftData(data.data);
      }

    } catch (e) {

    }
    setLoading(false);
  };

  return (<Card className={"gift-card"}>
    <div>
      <p>{t("Anobani gift card")}</p>
      <p>{t("The best gift for the best")}</p>

      {loading ? <CircleLoading width={120} height={120} color={"#1c3c53"} /> : <div>
        {
          giftData ?
            <div className={"accept-gift"}>
              <p className={"success"}>{t("Operation Completed Successfully")}</p>
              <p>{t("You Wallet Just Charged")}</p>
              <p className={"gift-price"}>{giftData?.price} {giftData?.currency_title}</p>
            </div>
            : <div className={"accept-gift"}>
              <p className={"failed"}>{t("Operation Failed")}</p>
            </div>
        }
      </div>}
    </div>

    <div>
      <Swiper
        className={"gift-card-slider"}
        dir={i18n.language === "en" ? "ltr" : "rtl"}
        loop={true}
        modules={[Pagination]}
        pagination
      >
        <SwiperSlide>
          {loading ? <div style={{ width: "90%", margin: "0 auto" }}>
            <Skeleton height={326} borderRadius={20} />
          </div> : <SlideOne value={giftData ? `${giftData?.price} ${giftData?.currency_title}` : null} />}
        </SwiperSlide>

        <SwiperSlide>
          {loading ? <div style={{ width: "90%", margin: "0 auto" }}>
            <Skeleton height={326} borderRadius={20} />
          </div> : <SlideTwo value={giftData ? `${giftData?.price} ${giftData?.currency_title}` : null} />}
        </SwiperSlide>
      </Swiper>
    </div>
  </Card>);
};

export default AcceptGiftCard;
