import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "@mantine/hooks";
import "./PhoneNumberInput.scss";
// Common components
import TextInput from "../others/TextInput";
// Sub components
import DialCodesDropdown from "./sub-components/DialCodesDropdown";

const PhoneNumberInput = ({
  dialCode,
  onDialCodeChange,
  phoneNumber,
  onPhoneNumberChange,
  disabled,

}) => {
  const { i18n } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useClickOutside(
    () => showDropdown === true && setShowDropdown(false)
  );
  const containerClassName = `phone-number-input ${
    i18n.language !== "en" ? "rtl" : undefined
  } ${isFocused ? "focused" : undefined} ${disabled ? "disabled" : undefined} ${
    showDropdown ? "open" : undefined
  }`;

  return (
    <div className={containerClassName} ref={dropdownRef}>
      <DialCodesDropdown
        dialCode={dialCode}
        onDialCodeChange={onDialCodeChange}
        phoneNumber={phoneNumber}
        onPhoneNumberChange={onPhoneNumberChange}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
      <TextInput
        value={phoneNumber}
        onChange={onPhoneNumberChange}
        isNumber
        toggleFocus={setIsFocused}
      />
    </div>
  );
};

export default PhoneNumberInput;
