import React, {useContext} from "react";
import "./TabNavigator.scss";
import {Link, useLocation} from "react-router-dom";
// Utils
import navbarConfigs from "../../../../utils/configs/navbar";
import NavbarContext from "../../../../utils/contexts/Navbar";
import {useSelector} from "react-redux";

const TabNavigator = () => {
    const location = useLocation();
    const {toggleModals, modalsDisplayStatus} = useContext(NavbarContext);
    const client = useSelector(state => state.client);

    return (
        <div className={"tab-navigator"}>
            {navbarConfigs.tabNavigator.map((item) => {
                if (!item.role || !item.role.length) {
                    return <Link
                        key={item.link}
                        className={location.pathname === item.link ? "active" : ''}
                        to={item.link}
                    >
                        {item.icon}
                    </Link>
                }

                if (item.role && !client) {
                    return <button
                        key={item.link}
                        className={location.pathname === item.link ? "active" : ''}
                        onClick={() => toggleModals({
                            slideMenu: false,
                            auth: true,
                            emailLogin: false,
                            otp: false,
                            logOut: false,
                            loading: false,
                            welcome: false,
                            maxConnection: false,
                            forgetPasswordRequest: false,
                            forgetPasswordVerify: false,
                            forgetPasswordConfirm: false
                        })}
                    >
                        {item.icon}
                    </button>
                }


                if (item.role && client && item.role.includes(client.role)) {
                    return <Link
                        key={item.link}
                        className={location.pathname === item.link ? "active" : ''}
                        to={item.link}
                    >
                        {item.icon}
                    </Link>
                }

                return null;

            })}
        </div>
    );
};

export default TabNavigator;
