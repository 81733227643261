import {useContext, useEffect, useState} from "react";
import "./css/PurchaseAndPreview.scss";
// Sub components
import AudioPlayer from "./AudioPlayer";
// Modals
import PodcastModal from "../../../../modals/podcast/Podcast";
// Utils
import ProductInfoContext from "../../../../../utils/contexts/Product";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {getRouter} from "../../../../../utils/helpers";
import Api from "../../../../../utils/api";
import {toast} from "react-toastify";
import axios from "../../../../../utils/http";
import CircleLoading from "../../../../common/others/CircleLoading";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


const PurchaseAndPreview = ({ packageType, data }) => {
    const { slug } = useParams();
    console.log('packageType33', data)
    const {t} = useTranslation();
    const location = useLocation();
    const [showPodcastModal, setShowPodcastModal] = useState(false);
    const {product, bookType} = useContext(ProductInfoContext);
    const [cart, setCart] = useState(product?.in_cart);
    const [cartpk, setCartpk] = useState(data?.in_cart);
    const [cartLoading, setCartLoading] = useState(false);
    const [cartLoadingpk, setCartLoadingpk] = useState(false);
    const [podcastEpisodes, setPodcastEpisodes] = useState([]);
    const [hasMoreEpisodes, setHasMoreEpisodes] = useState(false);
    const [hasMoreEpisodesLoading, setHasMoreEpisodesLoading] = useState(false);
    const [currentEpisodesPage, setCurrentEpisodesPage] = useState(1);
    const [currentTime, setCurrentTime] = useState();
    const client = useSelector((state) => state.client);
    const navigate = useNavigate();
    console.log('data.current_language', data)
    const current_language = data.current_language
    const addRemoveToCart = async (type) => {
        if (!client){
            toast(t("برای این خرید، ابتدا باید لاگین کرده باشید."), { type: "error" });
            return;
        }
        if (cartLoading)
            return;
        setCartLoading(true);
        try {
            const {data} = await axios.post(getRouter(Api.cart.cartAction, {
                productId: product?.id
            }), {
                type,
                language: product?.current_language
            });

            if (data.success) {
                toast(data.message, {type: "success"});
                setCart({...cart, [type]: !cart[type]});
            }
        } catch (e) {

        }
        setCartLoading(false);
    };
    var str = location?.search
    var newstr = str?.replace("?page=1&category=", "");

    const pkaddRemoveToCart = async () => {
        if (!client) {
            toast(t("برای این خرید، ابتدا باید لاگین کرده باشید."), { type: "error" });
            return;
        }
        if (cartLoading)
            return;
        setCartLoading(true);
        let types = ['electronic_book', "audio_book", "podcast", "magazine"]
        try {
            const { data } = await axios.post(`https://api.science-corner.net/api/user/cart/package/${newstr}`, {
                language: current_language
            });

            if (data.success) {
                toast(data.message, { type: "success" });
            }
        } catch (e) {

        }
        setCartLoading(false);
    };
    const pkaddRemove = async (type) => {
        if (!client) {
            toast(t("برای این خرید، ابتدا باید لاگین کرده باشید."), { type: "error" });
            return;
        }
        if (cartLoading)
            return;
        setCartLoading(true);
        try {
            const { data } = await axios.delete(`https://api.science-corner.net/api/user/cart/package/delete/${newstr}`);

            if (data.success) {
                toast(data.message, { type: "success" });
                setCart({ ...cart, [type]: !cart[type] });
            }
        } catch (e) {

        }
        setCartLoading(false);
    };


    const goToReaderPage = (type) => {
        return navigate("/reader", {
            state: {
                title: product?.title,
                slug: product?.slug,
                locale: product?.current_language,
                productType: product?.type,
                purchaseType: type,
                url: type === "demo" ? (product?.demo?.epub?.url || product?.demo?.pdf?.url) : (product?.main?.epub?.url || product?.main?.pdf?.url),
                lastPage: product?.last_page
            }
        });
    };

    const handleTimeUpdate = async (time) => {
        if (!client || client?.role != "user")
            return;

        if (time == currentTime)
            return;

        setCurrentTime(time);

        if (time % 5 !== 0)
            return;

        try {
            await axios.post(getRouter(Api.dashboard.readBook, {
                product: product?.slug
            }), {
                product_type: product?.type,
                product_lang: product?.current_language,
                time: 5
            });
        } catch (e) {

        }
    };

    function renderActions() {

        switch (packageType == true ? data?.type : product?.type) {
            case "electronic_book":
            case "audio_book":
                if (bookType === "electronic_book") {
                    return (
                        <>
                            {product?.is_purchased?.electronic_book || product?.purchase_type === "free" ?
                                <button onClick={() => goToReaderPage("main")} type={"button"}
                                    className={"button primary w-full h-14 "}>
                                    {t("Read Book")}
                                </button>
                                : <>
                                    {packageType == true ? <> <button type={"button"}
                                        className={"button primary w-full h-14"}>
                                        {cartLoadingpk ?
                                            <CircleLoading /> : (cart?.electronic_book ? t("Remove from Cart") : t("Buy E-Book"))}
                                    </button></> : <> <button type={"button"} onClick={() => addRemoveToCart("electronic_book")}
                                            className={"button primary w-full h-14"}>
                                        {cartLoading ?
                                            <CircleLoading /> : (cart?.electronic_book ? t("Remove from Cart") : t("Buy E-Book"))}
                                    </button></>}
                                </>
                            }
                            {(!product?.is_purchased?.electronic_book && product?.purchase_type === "paid" && product?.demo?.epub) &&
                                <button onClick={() => goToReaderPage("demo")} type={"button"}
                                    className={"button secondary w-full h-14 my-2"}>
                                    {t("Demo")}
                                </button>}
                        </>
                    );
                }
                if (data.type === "electronic_book") {
                    return (
                        <div className="w-full flex gap-2 items-center">

                            {data?.is_purchased?.electronic_book || data?.purchase_type === "free" ?
                                <button onClick={() => goToReaderPage("main")} type={"button"}
                                    className={"button primary w-full h-14 w-full "}>
                                    {t("Read Book")}
                                </button>
                                : <>
                                    <button type={"button"}
                                        className={"button ternary delete h-14 p-3 w-40"} onClick={() => pkaddRemove("electronic_book")} >
                                        {cartLoadingpk ?
                                            <CircleLoading /> : (t("Remove from Cart"))}
                                    </button>
                                    <button type={"button"}
                                        className={"button primary  h-14 p-1 w-40"} onClick={() => pkaddRemoveToCart("electronic_book")} >
                                        {cartLoadingpk ?
                                            <CircleLoading /> : t("Buy E-Book")}
                                    </button>

                                </>
                            }
                        </div>
                    );
                }
                if (bookType === "audio_book") {
                    return (
                        <>
                            {!product?.is_purchased?.audio_book && product?.purchase_type === "paid" ?
                                < button type={"button"} onClick={() => addRemoveToCart("audio_book")}
                                    className={"button primary w-full h-14"}>
                                    {cartLoading ?
                                        <CircleLoading/> : (cart?.audio_book ? t("Remove from Cart") : t("Buy Audio Book"))}
                                </button> : null
                            }
                            {(product?.is_purchased?.audio_book && product?.purchase_type === "paid") || product?.purchase_type === "free" ?
                                <AudioPlayer url={product?.main?.mp3?.url} onTimeUpdate={handleTimeUpdate}/> :
                                <AudioPlayer url={product?.demo?.mp3?.url}/>
                            }

                        </>
                    );
                }
                if (data.type === "audio_book") {
                    return (
                        <>
                            {!product?.is_purchased?.audio_book && product?.purchase_type === "paid" ?
                                < button type={"button"} onClick={() => addRemoveToCart("audio_book")}
                                    className={"button primary w-full h-14"}>
                                    {cartLoading ?
                                        <CircleLoading /> : (cart?.audio_book ? t("Remove from Cart") : t("Buy Audio Book"))}
                                </button> : null
                            }
                            {(product?.is_purchased?.audio_book && product?.purchase_type === "paid") || product?.purchase_type === "free" ?
                                <AudioPlayer url={product?.main?.mp3?.url} onTimeUpdate={handleTimeUpdate} /> :
                                <AudioPlayer url={product?.demo?.mp3?.url} />
                            }

                        </>
                    );
                }
                break;

            case "magazine":
                return (
                    <>
                        {!product?.is_purchased?.magazine && product?.purchase_type === "paid" ?
                            <button type={"button"} onClick={() => addRemoveToCart("magazine")}
                                className={"button primary w-full h-14"}>
                                {cartLoading ?
                                    <CircleLoading/> : (cart?.magazine ? t("Remove from Cart") : t("Buy Magazine"))}
                            </button>
                            :
                            <button onClick={() => goToReaderPage("main")} type={"button"} className={"button primary w-full h-14"}>
                                {t("Read Magazine")}
                            </button>
                        }
                        {
                            !product?.is_purchased?.magazine && product?.purchase_type === "paid" && product?.demo?.epub ?
                                <button onClick={() => goToReaderPage("demo")} type={"button"}
                                        className={"button secondary"}>
                                    {t("Demo")}
                                </button>
                                : null
                        }
                    </>
                );

            case "podcast":
                return (
                    <>
                        {!product?.is_purchased?.podcast && product?.purchase_type === "paid" ?
                            <button
                                type={"button"}
                                className={"button primary w-full h-14"}
                                onClick={() => {
                                    // setShowPodcastModal(true)
                                    addRemoveToCart("podcast");
                                }}
                            >
                                {cartLoading ?
                                    <CircleLoading/> : (cart?.podcast ? t("Remove from Cart") : t("Buy Podcast"))}
                            </button> : null
                        }
                        {podcastEpisodes?.length ? <button
                            type={"button"}
                            className={"button primary w-full h-14"}
                            onClick={() => setShowPodcastModal(true)}
                            style={{width: "100%"}}
                        >
                            {t("Other Podcasts of Collection")}
                        </button> : null}
                        {(product?.is_purchased?.podcast && product?.purchase_type === "paid") || product?.purchase_type === "free" ?
                            <AudioPlayer url={product?.main?.mp3?.url} onTimeUpdate={handleTimeUpdate}/> :
                            <AudioPlayer url={product?.demo?.mp3?.url}/>
                        }
                    </>
                );

            default:
                break;
        }
    }

    useEffect(() => {
        if (product?.type === "podcast") {
            getPodcastEpisodes();
        }
    }, [cart]);

    const getPodcastEpisodes = async (hasMore = false) => {
        if (hasMoreEpisodesLoading)
            return;
        setHasMoreEpisodesLoading(true);
        try {
            const {data} = await axios.get(getRouter(Api.products.podcastsEpisodes, {
                productId: product?.id
            }), {
                params: {
                    page: hasMore ? currentEpisodesPage + 1 : 1
                }
            });
            const {podcast} = data.data;
            setPodcastEpisodes([...podcastEpisodes, ...podcast.data]);
            setCurrentEpisodesPage(podcast.current_page);
            setHasMoreEpisodes(!!podcast.next_page_url);
        } catch (e) {

        }
        setHasMoreEpisodesLoading(false);
    };

    return (
        <div>
            {renderActions()}
            {(showPodcastModal && product?.type === "podcast" && podcastEpisodes.length) ?
                <PodcastModal toggle={setShowPodcastModal} episodes={podcastEpisodes} hasMore={hasMoreEpisodes}
                              onLoadMore={() => getPodcastEpisodes(hasMoreEpisodes)}
                              loadMoreLoading={hasMoreEpisodesLoading}/> : null}
        </div>
    );
};

export default PurchaseAndPreview;
