import React, {useState} from "react";
import "./css/PeriodSelector.scss";
import {useTranslation} from "react-i18next";
// Common components
import DropdownMenu from "../../../../common/others/DropdownMenu";

const PeriodSelector = ({onChange, period, periods}) => {
    const {t} = useTranslation()

    return (
        <div className={"period-selector"}>
            <p>{t("Study time report")}</p>

            <DropdownMenu value={period.dropdownTitle} maxWidth={150}>
                {periods.map(p => <p
                    onClick={() => {
                        onChange(p)
                    }
                    }>
                    {p.title}
                </p>)}
            </DropdownMenu>
        </div>
    );
};

export default PeriodSelector;
