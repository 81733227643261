import React from "react";
import "./css/Review.scss";
import {useTranslation} from "react-i18next";
// Utils
import tempMedia from "../../../../utils/tempMedia";

const Review = ({title, reviews}) => {
    const {t} = useTranslation();

    return (
        <div className={"audio-book-review"}>
            <p>
                {t("Review")} {title}
            </p>

            {reviews?.map(review => <>
                <p className={"review-title"}>{review.title}</p>

                <p className={"content"}>{review.text}</p>
                {review?.images?.map(image => <>
                    <img
                        alt={"No friends but mountain"}
                        src={image.image}
                    />
                    {image.description && <p>{image.description}</p>}
                </>)}
            </>)}


        </div>
    );
};

export default Review;
