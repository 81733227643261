import React, {useContext, useState} from "react";
import "./css/Details.scss";
import {useTranslation} from "react-i18next";
// Utils
import ProductInfoContext from "../../../../../utils/contexts/Product";
import AmountSelector from "../../../../home-related/gift-card/AmountSelector";
import {getDate} from "../../../../../utils/helpers";

const Details = ({ packageType, data }) => {
    console.log('details11111', packageType, data)
    const {t} = useTranslation();
    const {product, prices, bookType, toggleBookType} = useContext(ProductInfoContext);
    const [showFullText, setShowFullText] = useState(false);
    const authors = product?.authors.map(author => author?.name)?.join(", ");
    const translators = product?.translators.map(translator => translator?.name)?.join(", ");
    const speakers = product?.speakers.map(speaker => speaker?.name)?.join(", ");

    return (
        <div className={`${packageType == true ? 'mb-0 w-96' : 'details'}`}>
            {packageType == true ? <>
                <p className="font-bold text-3xl text-[#1c3c53]">نام کتب</p>
                <div className="flex items-center gap-3 flex flex-wrap text-[#516975] font-bold text-lg">
                    {data.titles.map((i, index) => <div key={index}>
                        <p>{i}</p>
                    </div>)}
                </div></> : <p>
                {product?.title}
            </p>}

            <div className={`${packageType == true ? 'text-[#516975] font-bold text-sm' : 'collaborators'}`}>
                {packageType == true ? <div className="flex flex-col my-2 gap-1">
                    <div className="flex items-center gap-2 flex-wrap">
                        <p>نویسندگان:</p>
                        {data.authors.map((i, index) => <p key={index}>{i.map(i => <p key={i.id}>{i.name}</p>)}</p>)}
                    </div>
                    <div className="flex items-center gap-2 flex-wrap">
                        <p>مترجمان:</p>
                        {data.translators.map((i, index) => <p key={index}>{i.map(i => <p key={i.id}>{i.name}</p>)}</p>)}
                    </div>

                </div> : <> {authors ? <p>{t("Authors")}: {authors}</p> : null}
                {translators ? <p>{t("Translators")}: {translators}</p> : null}
                    {speakers ? <p>{t("Speakers")}: {speakers}</p> : null}</>}
            </div>

            <div>
                {packageType == true ? null : <p>{getDate(product?.created_at)}</p>}
                {product?.type === "podcast" && <p>{t("Episode")} {product.episode}</p>}
            </div>
            <div>
                {packageType === true ? <>
                    <p className="text-[#1c3c53] font-bold">
                        {data?.type === "electronic_book" || data?.type === "audio_book" || data?.secondary_type === "audio_book"
                            ? t("See all Formats And Versions")
                            : t("Electronic Version")}
                    </p>
                </> : <>
                <p>
                    {product?.type === "electronic_book" || product?.type === "audio_book" || product?.secondary_type === "audio_book"
                        ? t("See all Formats And Versions")
                        : t("Electronic Version")}
                </p>
                        <p>
                            {!prices?.length ? t("Free") : ""}
                    </p></>}

            </div>


            <div className={"book-type-toggle"}>
                {packageType == true ? <>
                    {
                        data?.type === "electronic_book" && <button
                            type={"button"}
                            className={`${bookType === "electronic_book" ? " active" : ""} p-3 text-lg font-bold text-white bg-[#4169E1] my-2`}
                            onClick={() => toggleBookType("electronic_book")}
                        >
                            {t("E-book")}
                        </button>
                    }

                    {((data?.type === "audio_book" || data?.secondary_type === "audio_book")) &&
                        <button
                            type={"button"}
                            className={`button toggle${bookType === "audio_book" ? " active" : ""}`}
                            onClick={() => toggleBookType("audio_book")}
                        >
                            {t("Audio book")}
                        </button>}
                </> : <>
                        {
                    product?.type === "electronic_book" && product?.has_epub && <button
                        type={"button"}
                        className={`button toggle${bookType === "electronic_book" ? " active" : ""}`}
                        onClick={() => toggleBookType("electronic_book")}
                    >
                        {t("E-book")}
                    </button>
                }

                {((product?.type === "audio_book" || product?.secondary_type === "audio_book") && product?.has_audio) &&
                    <button
                        type={"button"}
                        className={`button toggle${bookType === "audio_book" ? " active" : ""}`}
                        onClick={() => toggleBookType("audio_book")}
                    >
                        {t("Audio book")}
                    </button>}
                </>}

            </div>
            {packageType == true ? <div style={{ marginBottom: 16 }}>
                {data.prices?.length ? <div className="p-3 flex w-full justify-between border rounded-xl bg-[#4169E1] text-white font-bold border-[#4169E1]">
                    {data.prices.filter(i => i.currency == 'IRR').map(i => <div className="flex items-center justify-center w-full gap-1 "> <p>{i.price}</p>
                        <p>{i.currency_name}</p>
                    </div>)}
                </div> : null}
            </div> :
            <div style={{marginBottom: 16}}>
                {prices?.length ? <AmountSelector values={prices} readOnly/> : null}
                </div>}


            {packageType == true ? null : <p className={"summary"}>
                {product?.description?.length <= 300 || showFullText
                    ? product?.description
                    : product?.description?.substr(0, 300) + "..."}
            </p>}
        </div>
    );
};

export default Details;
