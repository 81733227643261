import icons from "../icons";

const footerConfigs = {
  links: [
    {
      title: "About us",
      link: "/about-us",
    },
    {
      title: "Contact us",
      link: "/contact-us",
    },
    {
      title: "Work with us",
      link: "/work-with-us",
    },
    // {
    //   title: "Blog",
    //   link: "/blog",
    // },
    {
      title: "Guide",
      link: "/guide",
    },
    {
      title: "Terms of use",
      link: "/terms-of-use",
    },
    {
      title: "Login as Marketer",
      link: "/terms-of-use",
    },
  ],

  books: [
    "اهمیت",
    "بیشتر از کلمات",
    "پنج فوت از هم فاصله دارند",
    "زمین در حال ناپدید شدن",
    "باغ اسرار آمیز",
    "روز بدهی",
  ],

  socialMedia: [
    icons.telegram,
    icons.whatsApp,
    icons.googlePlay,
    icons.instagram,
    icons.twitter,
  ],
};

export default footerConfigs;
