import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./css/Product.scss";
// Utils
import icons from "../../../utils/icons";
import Skeleton from "react-loading-skeleton";
import {isPlainObject} from "lodash";
import CircleLoading from "./CircleLoading";
import {useTranslation} from "react-i18next";

export const ProductSkeleton = ({type}) => {
    const className = `product ${type}`;
    return (<div className={className}>
        <Skeleton className={"skeleton-img"} circle={type === "audio_book"}/>
        {/*<Skeleton width={"80%"}/>*/}
        {/*<Skeleton/>*/}
    </div>);

};
const Product = ({
                     data,
                     type,
                     forShoppingCart,
                     remove,
                     price,
                     removeLoading,
                     onRemoveLibrary,
                     onReadOnline,
                     onRemoveBookmark,
                     onEdit,
                     onDelete
                 }) => {
    const {t,i18n} = useTranslation();

    const className = `product ${type} ${forShoppingCart && "for-shopping-cart"}`;
    const to = `/products/${data.slug}`;
    const summary = data?.description?.length > 250 ? data?.description.substr(0, 250) + "..." : data?.description;
    const author = data.author && isPlainObject(data.author) ? data.author.name : data.author;
    const speaker = data.speaker && isPlainObject(data.speaker) ? data.speaker.name : data.speaker;
    const translator = data.translator && isPlainObject(data.translator) ? data.translator.name : data.translator;
    let contributors = [];
    if (author) contributors.push(author);
    if (speaker) contributors.push(speaker);
    if (translator) contributors.push(translator);
    contributors = contributors.join("، ");

    const formattedContributors = contributors?.length > 20 ? contributors.substr(0, 20) + "..." : contributors;

    return (<div className={className}>
        <Link to={to}>
            <img alt={"Product"} src={data.cover}/>
            {/*<p>*/}
            {/*    {type === "electronic_book" || type === "magazine" ? summary : type === "audio_book" || type === "podcast" ? `گوینده: ${speaker || author || translator}` : ""}*/}
            {/*</p>*/}
            {/*<p>{data.title}</p>*/}
            {/*<p>{formattedContributors}</p>*/}
        </Link>

        {/*{!forShoppingCart && !onRemoveLibrary && !onRemoveBookmark && !onDelete && !onEdit ? <div className={'product-arrow-icon'} style={i18n.language === 'kuk' ? {left:'initial',right:16,transform:'rotate(-90deg)'} : null}>{icons.arrowhead}</div> : null}*/}

        {forShoppingCart && (<>
            <p className={"price"}>{(+price.total_price)?.toLocaleString()} {t(price.currency)}</p>
            <button
                className={"button ternary delete"}
                type={"button"}
                onClick={() => remove()}
            >
                {removeLoading ?
                    <CircleLoading width={30} height={30} color={"#1c3c53"}/> : t("Remove From Cart")}
            </button>
        </>)}
        {onEdit ? (<>
            <button
                className={"button ternary delete read"}
                type={"button"}
                onClick={() => onEdit(data)}
            >
                {t('Edit')}
            </button>
        </>) : null}
        {onDelete ? (<>
            <button
                className={"button ternary delete"}
                type={"button"}
                onClick={() => onDelete(data)}
            >
                {removeLoading ? <CircleLoading width={30} height={30} color={"#1c3c53"}/> : t("Delete")}
            </button>
        </>) : null}
        {onReadOnline ? (<>
            <button
                className={"button ternary delete read"}
                type={"button"}
                onClick={() => onReadOnline(data)}
            >
                {type === "electronic_book" || type === "magazine" ? t("Read Online") : t("Listen Online")}
            </button>
        </>) : null}
        {onRemoveLibrary ? (<>
            <button
                className={"button ternary delete"}
                type={"button"}
                onClick={() => onRemoveLibrary(data)}
            >
                {removeLoading ?
                    <CircleLoading width={30} height={30} color={"#1c3c53"}/> : t("Remove From Library")}
            </button>
        </>) : null}
        {onRemoveBookmark ? (<>
            <button
                className={"button ternary delete"}
                type={"button"}
                onClick={() => onRemoveBookmark(data)}
            >
                {removeLoading ?
                    <CircleLoading width={30} height={30} color={"#1c3c53"}/> : t("Remove From Favorites")}
            </button>
        </>) : null}
    </div>);
};

export default Product;
