import React, {Fragment, useEffect, useState} from "react";
import "./css/Home.scss";
import {useTranslation} from "react-i18next";
import axios from "./../../utils/http";
import API from "./../../utils/api";
// Components specific to home
import IntroBanner, {IntroBannerSkeleton} from "../../components/home-related/home/IntroBanner";
import ProductSlider, {ProductSliderSkeleton} from "../../components/home-related/home/ProductSlider";
import SiteSuggestionsSlider, {
    SiteSuggestionsSliderSkeleton
} from "../../components/home-related/home/site-siggestions-slider/SiteSuggestionsSlider";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Home = () => {
    const {t} = useTranslation();
    const [homeData, setHomeData] = useState({
        sliders: [],
        most_purchased: [],
        electronic_books: [],
        electronic_book_banners: [],
        magazines: [],
        magazine_banners: [],
        lasting_podcasts: [],
        audio_books: [],
        audio_book_banners: [],
        podcasts: [],
        podcast_banners: [],
        sections: []
    });
    const [loading, setLoading] = useState(false);
    // const viewerRef = useRef(null);


    const getHomeData = async () => {
        setLoading(true);
        try {
            const {data} = await axios.get(API.public.home);

            if (data.success) {
                setHomeData(data.data);
            }
        } catch (e) {

        }
        setLoading(false);
    };

    useEffect(() => {
        getHomeData();
    }, []);

    if (loading) {
        return <div>
            <IntroBannerSkeleton/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"electronic_book"}/>
            <SiteSuggestionsSliderSkeleton/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"magazine"}/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"podcast"}/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"audio_book"}/>

            <SiteSuggestionsSliderSkeleton/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"podcast"}/>

            <div className={"title-container"}>
                <p className={"home-title"}><Skeleton width={200}/></p>
                <p><Skeleton width={150}/></p>
            </div>
            <ProductSliderSkeleton type={"audio_book"}/>
        </div>;
    }


    return (
        <div>
            {homeData.sliders && homeData.sliders.length ? <IntroBanner banner={homeData.sliders}/> : null}

            {
                homeData.most_purchased.length ? <>
                    <div className={"title-container"}>
                        <p className={"home-title"}>{t("Best Selling Books")}</p>
                        <Link to={"/products?sort_by=purchases"}>{t("See all")}</Link>
                    </div>
                    <ProductSlider products={homeData.most_purchased}/>
                </> : null
            }

            {homeData.electronic_books.length ? <>
                <div className={"title-container"}>
                    <p className={"home-title"}>{t("New Books")}</p>
                    <Link to={"/products?product_type[]=electronic_book&sort_by=latest"}>{t("See all")}</Link>
                </div>
                <ProductSlider products={homeData.electronic_books}/>
            </> : null}

            {homeData.electronic_book_banners.length ?
                <SiteSuggestionsSlider banners={homeData.electronic_book_banners}/> : null}

            {
                homeData.audio_books.length ? <>
                    <div className={"title-container"}>
                        <p className={"home-title"}>{t("Audio books")}</p>
                        <Link to={"/products?product_type[]=audio_book&sort_by=latest"}>{t("See all")}</Link>
                    </div>
                    <ProductSlider products={homeData.audio_books} type={"audio_book"}/>
                </> : null
            }

            {homeData.audio_book_banners.length ? <SiteSuggestionsSlider banners={homeData.audio_book_banners}/> : null}

            {
                homeData.magazines.length ? <>
                    <div className={"title-container"}>
                        <p className={"home-title"}>{t("Journals")}</p>
                        <Link to={"/products?product_type[]=magazine&sort_by=latest"}>{t("See all")}</Link>
                    </div>
                    <ProductSlider products={homeData.magazines}/>
                </> : null
            }

            {homeData.magazine_banners.length ? <SiteSuggestionsSlider banners={homeData.magazine_banners}/> : null}


            {/*{homeData.lasting_podcasts.length ? <>*/}
            {/*    <div className={"title-container"}>*/}
            {/*        <p className={"home-title"}>{t("Latest podcasts")}</p>*/}
            {/*        <Link to={"/products?product_type[]=podcast&sort_by=latest"}>{t("See all")}</Link>*/}
            {/*    </div>*/}
            {/*    <ProductSlider products={homeData.lasting_podcasts}/>*/}
            {/*</> : null}*/}


            {/*{homeData.podcasts.length ? <>*/}
            {/*    <div className={"title-container"}>*/}
            {/*        <p className={"home-title"}>{t("Podcasts")}</p>*/}
            {/*        <Link to={"/products?product_type[]=podcast&sort_by=latest"}>{t("See all")}</Link>*/}
            {/*    </div>*/}
            {/*    <ProductSlider products={homeData.podcasts}/>*/}
            {/*</> : null}*/}
            {/*{homeData.podcast_banners.length ? <SiteSuggestionsSlider banners={homeData.podcast_banners}/> : null}*/}
            {homeData.sections.map((section, index) => {
                if (!section.products.length)
                    return null

                return <Fragment key={index}>
                    <div className={"title-container"}>
                        <p className={"home-title"}>{section.title}</p>
                        <Link
                            to={`/products?${section.filter === 'category' ? section.filter : `${section.filter}[]`}=${section.value}&sort_by=latest`}>{t("See all")}</Link>
                    </div>
                    <ProductSlider products={section.products}/>
                </Fragment>
            })}
        </div>
    );
};

export default Home;
