import React, {useContext, useEffect, useState} from "react";
import "./css/FormRightSection.scss";
import {useTranslation} from "react-i18next";
// Common components
import Checkbox from "../../../../../common/others/Checkbox";
import DatePickerInput from "../../../../../common/others/DatePickerInput";
import DropdownMenu from "../../../../../common/others/DropdownMenu";
import RadioButton from "../../../../../common/others/RadioButton";
import TextInput from "../../../../../common/others/TextInput";
import ProductFormContext from "../../../../../../utils/contexts/ProductForm";
import AmountSelector from "../../../../../home-related/gift-card/AmountSelector";
import {useSelector} from "react-redux";
import CircleLoading from "../../../../../common/others/CircleLoading";
import axios from "./../../../../../../utils/http";
import Api from "../../../../../../utils/api";
import {getLocale, getRouter} from "../../../../../../utils/helpers";
import Badge from "../../../../../common/others/Badge";

const FormRightSection = () => {
    const {t} = useTranslation();
    const {
        info,
        productData,
        setProductData,
        globalProductData,
        setGlobalProductData,
        locale: currentLocale,
        setLocale,
        slug,
        productId
    } = useContext(ProductFormContext);
    const categories = useSelector(state => state.categories);
    const [author, setAuthor] = useState("");
    const [translator, setTranslator] = useState("");
    const [speaker, setSpeaker] = useState("");
    const [authorDropDown, setAuthorDropDown] = useState(false);
    const [authorsSearchLoading, setAuthorsSearchLoading] = useState(false);
    const [authorsAddLoading, setAuthorsAddLoading] = useState(false);
    const [translatorDropDown, setTranslatorDropDown] = useState(false);
    const [translatorsSearchLoading, setTranslatorsSearchLoading] = useState(false);
    const [translatorsAddLoading, setTranslatorsAddLoading] = useState(false);
    const [speakerDropDown, setSpeakerDropDown] = useState(false);
    const [speakersSearchLoading, setSpeakersSearchLoading] = useState(false);
    const [speakersAddLoading, setSpeakersAddLoading] = useState(false);
    const [authors, setAuthors] = useState("");
    const [translators, setTranslators] = useState("");
    const [speakers, setSpeakers] = useState("");

    const handleSearchAuthors = async () => {
        setAuthorDropDown(false);
        setAuthorsSearchLoading(true)
        try {
            const {data} = await axios.get(getRouter(Api.public.collaborators, {
                type: "author"
            }), {
                params: {
                    q: author, limit: 30
                }
            });

            setAuthors(data.data.collaborators.map(c => ({
                key: c.id, value: c.name
            })));
        } catch (e) {
        }
        setAuthorsSearchLoading(false)
        setAuthorDropDown(true);
    };

    const handleAddAuthor = async () => {
        setAuthorDropDown(false);
        setAuthorsAddLoading(true)
        try {
            const {data} = await axios.post(getRouter(Api.publisher.addCollaborator, {
                type: "author"
            }), {
                name: author
            }, {
                params: {
                    lang: getLocale()
                }
            });

            if (data.success) {
                const {collaborator} = data.data;
                setProductData({
                    ...productData, authors: [...productData.authors, {key: collaborator.id, value: collaborator.name}]
                });
            }

        } catch (e) {
        }
        setAuthorsAddLoading(false)
    };

    const handleSearchTranslator = async () => {
        setTranslatorDropDown(false);
        setTranslatorsSearchLoading(true)
        try {
            const {data} = await axios.get(getRouter(Api.public.collaborators, {
                type: "translator"
            }), {
                params: {
                    q: translator, limit: 30
                }
            });

            setTranslators(data.data.collaborators.map(c => ({
                key: c.id, value: c.name
            })));
        } catch (e) {
        }
        setTranslatorsSearchLoading(false)
        setTranslatorDropDown(true);
    };

    const handleAddTranslator = async () => {
        setTranslatorDropDown(false);
        setTranslatorsAddLoading(true)
        try {
            const {data} = await axios.post(getRouter(Api.publisher.addCollaborator, {
                type: "translator"
            }), {
                name: translator
            }, {
                params: {
                    lang: getLocale()
                }
            });

            if (data.success) {
                const {collaborator} = data.data;
                setProductData({
                    ...productData,
                    translators: [...productData.translators, {key: collaborator.id, value: collaborator.name}]
                });
            }

        } catch (e) {
        }
        setTranslatorsAddLoading(false)
    };

    const handleSearchSpeaker = async () => {
        setSpeakerDropDown(false);
        setSpeakersSearchLoading(true)
        try {
            const {data} = await axios.get(getRouter(Api.public.collaborators, {
                type: "speaker"
            }), {
                params: {
                    q: speaker, limit: 30
                }
            });

            setSpeakers(data.data.collaborators.map(c => ({
                key: c.id, value: c.name
            })));
        } catch (e) {
        }
        setSpeakersSearchLoading(false)
        setSpeakerDropDown(true);
    };

    const handleAddSpeaker = async () => {
        setSpeakerDropDown(false);
        setSpeakersAddLoading(true)
        try {
            const {data} = await axios.post(getRouter(Api.publisher.addCollaborator, {
                type: "speaker"
            }), {
                name: speaker
            }, {
                params: {
                    lang: getLocale()
                }
            });

            if (data.success) {
                const {collaborator} = data.data;
                setProductData({
                    ...productData,
                    speakers: [...productData.speakers, {key: collaborator.id, value: collaborator.name}]
                });
            }

        } catch (e) {
        }
        setSpeakersAddLoading(false)
    };
    console.log('info', info, currentLocale)
    return (
        <div className={"form-right-section"}>
        <p className={"product-title"}>{slug || productId ? t("Edit Product") : t("Add new product")}</p>

            {info?.languages?.length ? <AmountSelector value={currentLocale}
                                                   updateValue={locale => setLocale(locale)}
                                                   values={info?.languages}/> : null}

            <TextInput
            placeholder={t("Name")}
            value={productData?.name}
            onChange={(e) => setProductData({
                ...productData,
                name: e.target.value
            })}
        />

        <div>
                <DropdownMenu
                value={globalProductData.productType ? globalProductData.productType.value : t("Select Product Type")}>
                {productId ? null : info?.product_types?.map(type => <div key={type.key}
                                                                          onClick={() => {
                                                                              if (globalProductData.productType?.key === type.key)
                                                                                  return;
                                                                              setGlobalProductData({
                                                                                  ...globalProductData,
                                                                                  productType: type,
                                                                                  hasAudioBook: type.has_audio_book ? globalProductData?.hasAudioBook : false
                                                                              });
                                                                              setProductData({
                                                                                  ...productData,
                                                                                  demo: null,
                                                                                  audioDemo: null,
                                                                                  main: null,
                                                                                  audioMain: null
                                                                              })
                                                                          }}>
                    {type.value}
                </div>)}
            </DropdownMenu>
                {globalProductData?.productType?.has_audio_book ? <div>
                <Checkbox checked={!!globalProductData?.hasAudioBook} onChange={(checked) => {
                    if (productId) return;
                    setGlobalProductData({
                        ...globalProductData, hasAudioBook: checked
                    });
                }} title={t("Has Audio book")}/>
            </div> : null}
                <div className="categories-container">
                <DropdownMenu
                    value={globalProductData.category ? globalProductData.category.title : t("Select Category")}>
                    {productId ? null : categories?.map(category => <div key={category.id}
                                                                         onClick={() => {
                                                                             setGlobalProductData({
                                                                                 ...globalProductData,
                                                                                 category,
                                                                                 subCategory: null,
                                                                                 filterCategory: null
                                                                             });
                                                                         }}>
                        {category.title}
                    </div>)}
                </DropdownMenu>
            </div>
                <div className="sub-categories">
                <DropdownMenu
                    value={globalProductData.subCategory ? globalProductData.subCategory.title : t("Select Sub Category")}>
                    {productId ? null : globalProductData?.category?.sub_categories?.map(category => <div
                        key={category.id}
                        onClick={() => {
                            setGlobalProductData({
                                ...globalProductData,
                                subCategory: category,
                                filterCategory: null
                            });
                        }}>
                        {category.title}
                    </div>)}
                </DropdownMenu>
            </div>
                <div className="filter-categories-genres-container">

                <div className="filter-category-container">
                    <DropdownMenu
                        value={globalProductData.filterCategory ? globalProductData.filterCategory.title : t("Select Filter Category")}>
                        {productId ? null : globalProductData?.subCategory?.sub_categories?.map(category => <div
                            key={category.id}
                            onClick={() => {
                                setGlobalProductData({
                                    ...globalProductData,
                                    filterCategory: category
                                });
                            }}>
                            {category.title}
                        </div>)}
                    </DropdownMenu>
                </div>
                    {console.log('globalProductData', globalProductData)}
                <div className="genre-container">
                    <DropdownMenu
                        value={globalProductData.genre ? globalProductData.genre.title : t("Select Genre")}>
                        {productId ? null : info?.genres?.map(genre => <div key={genre.id}
                                                                            onClick={() => {
                                                                                setGlobalProductData({
                                                                                    ...globalProductData, genre
                                                                                });
                                                                            }}>
                            {genre.title}
                        </div>)}
                    </DropdownMenu>
                </div>
            </div>
        </div>

            <textarea placeholder={t("Captions")} onChange={(e) => setProductData({
            ...productData, description: e.target.value
        })} value={productData?.description}></textarea>

            <TextInput
            placeholder={t("Authors")}
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            searchable={!!author}
            onSearchClick={handleSearchAuthors}
            searchLoading={authorsSearchLoading}
            searchItems={authors}
            showDropdown={authorDropDown}
            onSearchItemClick={(item) => {
                const authorExists = !!productData?.authors?.find(pa => pa.key == item.key);
                if (authorExists)
                    return;
                setProductData({
                    ...productData, authors: [...productData.authors, item]
                });
            }}
            addable
            onAddClick={handleAddAuthor}
            addLoading={authorsAddLoading}
        />
            {productData?.authors?.length ? <div className={"badges-container"}>
            {productData?.authors?.map((a, index) => <Badge key={index} title={a.value} onRemoveClick={() => {
                const newAuthors = productData?.authors?.filter(pa => pa.key != a.key);
                setProductData({
                    ...productData, authors: newAuthors
                });
            }
            }/>)}
        </div> : null}
            <TextInput
            placeholder={t("Translators")}
            value={translator}
            onChange={(e) => setTranslator(e.target.value)}
            searchable={!!translator}
            onSearchClick={handleSearchTranslator}
            searchLoading={translatorsSearchLoading}
            searchItems={translators}
            showDropdown={translatorDropDown}
            onSearchItemClick={(item) => {
                const translatorExists = !!productData?.translators?.find(pa => pa.key == item.key);
                if (translatorExists)
                    return;
                setProductData({
                    ...productData, translators: [...productData.translators, item]
                });
            }}
            addable
            onAddClick={handleAddTranslator}
            addLoading={translatorsAddLoading}
        />
            {productData?.translators?.length ? <div className={"badges-container"}>
            {productData?.translators?.map(a => <Badge title={a.value} onRemoveClick={() => {
                const newTranslators = productData?.translators?.filter(pa => pa.key != a.key);
                setProductData({
                    ...productData, translators: newTranslators
                });
            }
            }/>)}
        </div> : null}
            <TextInput
            placeholder={t("Speakers")}
            value={speaker}
            onChange={(e) => setSpeaker(e.target.value)}
            searchable={!!speaker}
            onSearchClick={handleSearchSpeaker}
            searchLoading={speakersSearchLoading}
            searchItems={speakers}
            showDropdown={speakerDropDown}
            onSearchItemClick={(item) => {
                const speakerExists = !!productData?.speakers?.find(pa => pa.key == item.key);
                if (speakerExists)
                    return;
                setProductData({
                    ...productData, speakers: [...productData.speakers, item]
                });
            }}
            addable
            onAddClick={handleAddSpeaker}
            addLoading={speakersAddLoading}
        />
            {productData?.speakers?.length ? <div className={"badges-container"}>
            {productData?.speakers?.map(a => <Badge title={a.value} onRemoveClick={() => {
                const newSpeakers = productData?.speakers?.filter(pa => pa.key != a.key);
                setProductData({
                    ...productData, speakers: newSpeakers
                });
            }
            }/>)}
        </div> : null}
            <DatePickerInput
            placeholder={t("Published Date")}
            value={productData?.publishedDate}
            onChange={(date) => setProductData({
                ...productData, publishedDate: date
            })}
        />
            <TextInput
            placeholder={t("Season")}
            value={productData?.seasons}
            onChange={(e) => setProductData({
                ...productData, seasons: e.target.value
            })}
        />

            {globalProductData?.productType?.key === 'PODCAST' ?
            <TextInput
                placeholder={t("Episode")}
                value={globalProductData?.episode || ""}
                onChange={(e) => setGlobalProductData({
                    ...globalProductData,
                    episode: e.target.value
                })}
                isNumber
            />
            : null}

            {globalProductData?.productType?.key === 'ELECTRONIC_BOOK' || globalProductData?.productType?.key === 'MAGAZINE' ?
                <TextInput
                    placeholder={t("Volume")}
                    value={productData?.volume}
                    onChange={(e) => setProductData({
                        ...productData, volume: e.target.value
                    })}
                /> : null}
        </div>
    )
};

export default FormRightSection;
