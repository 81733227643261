import Lottie from "react-lottie";
import loadingAnimationJsonData from "./../../../assets/lottie/loading.json";
import { replaceColor } from "lottie-colorify";

const CircleLoading = ({ color = "#ffffff", width = 45, height = 45 }) => {
  return (
    <Lottie width={width} height={height} options={{
      loop: true,
      autoplay: true,
      animationData: replaceColor("#F5F5F5", color, loadingAnimationJsonData),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    }} isClickToPauseDisabled  />
  );
};

export default CircleLoading;
