import React, { useEffect, useState } from "react";
// Sub components
import Header from "./sub-components/Header";
import Table from "./sub-components/Table";
import MyPublishersAndProfitHeader from "../../marketeer-specific/MyPublishersAndProfitHeader";
import { useTranslation } from "react-i18next";
import axios from "../../../../utils/http";
import Api from "../../../../utils/api";

const SalesRecords = () => {
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [totalSale, setTotalSale] = useState(0);
  const [withdrawableSale, setWithdrawableSale] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    getSaleRecords();
  }, [sortBy, currency]);

  const getSaleRecords = async () => {
    try {
      const { data } = await axios.get(Api.dashboard.saleRecords, {
        params: {
          currency: currency?.currency,
          sort_by: sortBy
        }
      });
      if (data.success) {
        setRecords(data.data.records);
        setTotalSale(data.data.total_record);
        setWithdrawableSale(data.data.withdrawable_record);
        if (!currencies.length)
          setCurrencies(data.data.currencies);
      }
    } catch (e) {

    }
  };

  return (
    <div>
      <MyPublishersAndProfitHeader title={t("Sale Records")} totalProfit={totalSale}
                                   withdrawableProfit={withdrawableSale}
                                   onSortChange={(sort) => setSortBy(sort)}
                                   currencies={currencies}
                                   onCurrencyChange={(c) => setCurrency(c)} />
      <Table records={records} />
    </div>
  );
};

export default SalesRecords;
