import {useRef, useState} from "react";
import "./css/AudioPlayer.scss";
// Utils
import icons from "../../../../../utils/icons";

const AudioPlayer = ({url,onTimeUpdate}) => {
    const playerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(50);
    const [isMuted, setIsMuted] = useState(false);
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0);

    function renderTimer() {
        if (duration === 0 && time === 0) {
            return "00:00/00:00";
        }
        if (playerRef.current) {
            // Current time
            let currentMinutes = Math.floor(playerRef.current.currentTime / 60);
            let currentSeconds = Math.floor(playerRef.current.currentTime % 60);
            if (currentMinutes < 10) {
                currentMinutes = "0" + currentMinutes;
            }
            if (currentSeconds < 10) {
                currentSeconds = "0" + currentSeconds;
            }
            const currentTime = currentMinutes + ":" + currentSeconds;
            // Total time
            let totalMinutes = Math.floor(playerRef.current.duration / 60);
            let totalSeconds = Math.floor(playerRef.current.duration % 60);
            if (totalMinutes < 10) {
                totalMinutes = "0" + totalMinutes;
            }
            if (totalSeconds < 10) {
                totalSeconds = "0" + totalSeconds;
            }
            const totalTime = totalMinutes + ":" + totalSeconds;
            // Timer
            return currentTime + "/" + totalTime;
        } else {
            return "00:00/00:00";
        }
    }

    function toggle() {
        if (isPlaying) {
            setIsPlaying(false);
            playerRef.current.pause();
        } else {
            setIsPlaying(true);
            playerRef.current.play();
        }
    }

    function handleTimeUpdate(e) {
        setDuration( Math.floor(e.target.duration))
        const totalTime = Math.floor(e.target.duration);
        const currentTime = Math.floor(e.target.currentTime);
        const percentage = (currentTime * 100) / totalTime;
        setTime(Math.floor(e.target.currentTime));
        if (onTimeUpdate)
            onTimeUpdate(currentTime);
        currentTime === totalTime && setIsPlaying(false);
    }

    function handleTimeChange(e) {
        const selectedTime = Math.floor(e.target.value);
        setTime(selectedTime);
        playerRef.current.currentTime = e.target.value;
    }

    function handleAudioChange(e) {
        playerRef.current.volume = e.target.value / 100;
        setVolume(e.target.value);
    }

    function handleMute() {
        if (isMuted) {
            playerRef.current.volume = volume / 100;
            setIsMuted(false);
        } else {
            playerRef.current.volume = 0;
            setIsMuted(true);
        }
    }

    return (
        <div className={"audio-player"}>
            <audio
                id={"audio-player"}
                ref={playerRef}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={(e) => setDuration(Math.floor(e.target.duration))}
            >
                <source src={url} type={"audio/mp3"}/>
            </audio>

            <input
                type={"range"}
                value={isMuted ? 0 : volume}
                onChange={handleAudioChange}
            />
            <button onClick={handleMute}>
                {isMuted ? icons.audioStopped : icons.audioPlaying}
            </button>
            <p>{renderTimer()}</p>
            <input type={"range"} value={time} max={duration} onChange={handleTimeChange}/>
            <button onClick={toggle}>{isPlaying ? icons.stop : icons.play}</button>
        </div>
    );
};

export default AudioPlayer;
