import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/Reply.scss";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../common/others/ModalWrapper";
import TextInput from "../common/others/TextInput";
import CircleLoading from "../common/others/CircleLoading";
import axios from "../../utils/http";
import { getRouter } from "../../utils/helpers";
import Api from "../../utils/api";
import { toast } from "react-toastify";
import ProductInfoContext from "../../utils/contexts/Product";

const ReplyModal = (props) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { product } = useContext(ProductInfoContext);

  const addComment = async () => {
    if (loading)
      return;

    setLoading(true);
    try {
      const { data } = await axios.post(getRouter(Api.comments.replyComment, {
        productId: product.id,
        commentId: props.commentId
      }), {
        title,
        comment
      });

      if (data.success) {
        toast(t("Your Comment has Been Submitted and Will Show it After Confirmation"), { type: "success" });
        props.toggleDisplay(false);
      }

    } catch (e) {

    }
    setLoading(false);
  };


  return (
    <ModalWrapper className={"modal reply-modal"} toggle={() => props.toggleDisplay(false)}>
      <div>

        <div className={"center"} style={{ marginTop: 8 }}>
          <p>{t("Reply to")}</p>
          <p>({props.name})</p>
        </div>

        <TextInput
          placeholder={t("Title")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <textarea onChange={(e) => setComment(e.target.value)} value={comment} placeholder={t("Add your comment")} />

        <div>
          <button type={"button"} onClick={() => addComment()} className={"button primary"}>
            {loading ? <CircleLoading /> : t("Confirm")}
          </button>
          <button type={"button"} className={"button secondary"}>
            {t("Cancel")}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ReplyModal;
