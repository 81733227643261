import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Product.scss";
// Sub components
import ProductInfo from "./sub-components/product-info/ProductInfo";
import Review from "./sub-components/others/Review";
import Comments from "./sub-components/others/Comments";
import Suggestions from "./sub-components/others/Suggestions";
// Utils
import ProductContext from "../../utils/contexts/Product";
import axios from "../../utils/http";
import API from "../../utils/api";
import { getRouter } from "../../utils/helpers";
import CircleLoading from "../common/others/CircleLoading";
import { useTranslation } from "react-i18next";
const Product = ({ packageType }) => {
  console.log("package", packageType)
  const { t } = useTranslation();
  const { slug } = useParams();
  const [publisher, setPublisher] = useState();
  const [otherProducts, setOtherProducts] = useState([]);
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [publisherProducts, setPublisherProducts] = useState([]);
  const [product, setProduct] = useState();
  const [comments, setComments] = useState([]);
  const [bookType, setBookType] = useState("");
  const [commentCurrentPage, setCommentCurrentPage] = useState(1);
  const [hasMoreComment, setHasMoreComment] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [hasAudioBook, setHasAudioBook] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getProduct();
    getComments();
  }, [slug]);

  const search = useLocation().search;
  console.log("1", search);

  const getProduct = async (lang = null) => {

    setLoading(true);
    try {
      const params = {};
      // { packageType == true ? :}
      if (packageType == true) {
        const { data } = await axios.get(`https://api.science-corner.net/api/products${search}&details=true`)
        setData(data.data.products)
      }
      if (lang)
        params.lang = lang;
      const { data } = await axios.get(getRouter(API.products.product, { slug }), {
        params
      });

      if (data.success) {
        setPublisher(data.data.publisher);
        setProduct(data.data.product);
        setPrices(data.data.product?.prices.map(price => ({
          key: price?.id,
          value: `${price?.price} ${t(price.currency_name)}`
        })) || []);
        setOtherProducts(data.data.most_purchased_products);
        setCollectionProducts(data.data.collection_products);
        setPublisherProducts(data.data.publisher_products);
        // setMostPurchasedProducts(data.data.most_purchased_products);
        // setNewProducts(data.data.new_products);
        const prod = data.data.product;
        if (prod?.type === "electronic_book" && prod?.has_epub) {
          setBookType("electronic_book");
        } else if ((prod?.type === "audio_book" || prod?.secondary_type === "audio_book") && prod?.has_audio) {
          setBookType("audio_book");
        } else {
          setBookType(prod?.type);
        }
        setHasAudioBook(prod?.secondary_type === "audio_book");
      }
      setLoading(false);
    } catch (e) {

    }

    setLoading(false)

  };

  const getComments = async () => {
    if (moreLoading)
      setMoreLoading(true);

    setMoreLoading(true);

    try {
      const { data } = await axios.get(getRouter(API.products.comments, { slug }), {
        params: {
          page: hasMoreComment ? commentCurrentPage + 1 : commentCurrentPage
        }
      });


      if (data.success) {
        setComments([...comments, ...data.data.data]);
        setCommentCurrentPage(data.data.current_page);
        setHasMoreComment(!!data.data.next_page_url);
      }
    } catch (e) {

    }
    setMoreLoading(false);
  };

  const handleChangeLanguage = (lang) => {
    getProduct(lang);
  };


  if (loading)
    return <div className={"product-loading-container"}>
      <CircleLoading width={250} height={250} color={"#1c3c53"} />
    </div>;
  console.log('data1111111111111111', data)
  return (
    <div>
      <ProductContext.Provider
        value={{
          product,
          publisher,
          prices,
          bookType,
          toggleBookType: setBookType,
          hasAudioBook,
          onLanguageChange: handleChangeLanguage
        }}
      >
        <ProductInfo product={product} data={data} packageType={packageType} />
        {packageType ? null : <Review title={product?.title} reviews={product?.review} />}
        {packageType == true ? null : <Comments comments={comments} onLoadMore={() => getComments()} />}
        {hasMoreComment && <div className="load-more-container">
          <button onClick={() => getComments()}>{moreLoading ? <CircleLoading /> : t("Load More")}</button>
        </div>}
        <Suggestions otherProducts={otherProducts} collectionProducts={collectionProducts}
                     publisherProducts={publisherProducts} />
      </ProductContext.Provider>
    </div>
  );
};

export default Product;
