import React, {useEffect, useState} from "react";
import "./SearchBox.scss";
// Utils
import icons from "../../../../../../utils/icons";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";

const SearchBox = () => {
    const [search, setSearch] = useState("");
    const query = useLocation().search;
    const navigate = useNavigate();
    useEffect(() => {
        setSearch(queryString.parse(query)?.title || "")
    }, [])

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        navigate(`/products?title=${search}`);
    }

    return (
        <form onSubmit={handleSubmitSearch} className={"search-box"}>
            {icons.search}

            <input type={"text"} value={search} onChange={e => setSearch(e.target.value)}/>
        </form>
    );
};

export default SearchBox;
