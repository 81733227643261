import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./css/TextInput.scss";
// Utils
import icons from "../../../utils/icons";
import CircleLoading from "./CircleLoading";

const TextInput = ({
                       className,
                       disabled,
                       error,
                       isNumber,
                       isPassword,
                       onChange,
                       onBlur,
                       placeholder,
                       value,
                       ltr,
                       toggleFocus,
                       addable = false,
                       onAddClick = () => {
                       },
                       searchable = false,
                       searchIcon = icons.search,
                       onSearchClick = () => {
                       },
                       searchItems = [],
                       onSearchItemClick = (item) => {
                       },
                       showDropdown = false,
                       searchLoading = false,
                       addLoading = false
                   }) => {
    const {i18n} = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showDropdownItems, setShowDropdownItems] = useState(showDropdown);
    const [filteredSearchItems, setFilteredSearchItems] = useState(searchItems);
    const hasError = error?.message && error.visibility;
    const containerClassName = `text-input ${className} ${value !== "" ? "filled" : undefined} ${i18n.language === "en" ? "ltr" : undefined} ${isFocused ? "focused" : undefined} ${disabled ? "disabled" : undefined} ${hasError ? "has-error" : undefined}`;
    const inputClassName = `${isPassword ? "password" : undefined} ${isNumber || isPassword || ltr ? "ltr" : undefined}`;
    const type = isNumber ? "number" : isPassword && !showPassword ? "password" : "text";

    const dropdownMenuRef = useRef();
    useEffect(() => {
        setShowDropdownItems(showDropdown);
        const scrollTopTop = () => {
            dropdownMenuRef.current?.scrollTo(0, 0);
        };
        document.addEventListener("click", scrollTopTop);
        return () => document.removeEventListener("click", scrollTopTop);
    }, [showDropdown]);

    return (<div className={containerClassName}>
        <input
            type={type}
            className={inputClassName}
            onFocus={() => {
                setIsFocused(true);
                toggleFocus && toggleFocus(true);
            }}
            onBlur={() => {
                setIsFocused(false);
                // setShowDropdown(false);
                toggleFocus && toggleFocus(false);
                onBlur && onBlur();
            }}
            value={value}
            onKeyDown={(e) => isNumber && (e.key === "e" || e.key === "E") && e.preventDefault()}
            onChange={(e) => {
                onChange(e);
                if (!searchable) return;
                if (e.target.value === "") {
                    setShowDropdownItems(false);
                }
                // setShowDropdown(true);
                // if (e.target.value === "") {
                //   setFilteredSearchItems(searchItems);
                // } else {
                //   setFilteredSearchItems(searchItems?.filter(item => item.value?.includes(e.target.value)));
                // }
            }}
            disabled={disabled}
            pattern={isNumber && "[0 ,9]"}
        />

        <label>{placeholder}</label>
        {isPassword || searchable || addable ? <div className={"text-input-icons"}>
            {searchable ? <div onClick={() => {
                onSearchClick()
            }}>
                {searchLoading ? <CircleLoading width={30} height={30} color={"#1C3C53"}/> : searchIcon}
            </div> : null}
            {addable ? <div onClick={onAddClick}>
                {addLoading ? <CircleLoading width={30} height={30} color={"#1C3C53"}/> : icons.add}
            </div> : null}

            {isPassword && (<div onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? icons.inputVisible : icons.inputInvisible}
            </div>)}
        </div> : null}

        {(searchable && value !== "" && searchItems.length) ?
            <div className={`dropdown-items ${showDropdownItems ? "active" : ""}`} ref={dropdownMenuRef}>
                {searchItems.map(item => <p key={item.key} onClick={() => {
                    onSearchItemClick(item);
                    setShowDropdownItems(false);
                    onChange({target: {value: ""}});
                }}>{item.value}</p>)}
            </div> : null}

        {hasError && <p className={'text-input-error'}>{error.message}</p>}
    </div>);
};

export default TextInput;
