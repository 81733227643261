import React, {useEffect, useRef, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "./utils/http";
// Basic components
import Navbar from "./components/basic/navbar/Navbar";
import Footer from "./components/basic/footer/Footer";
import BookRequestForm from "./components/basic/BookRequestForm";
// Views
// // Home-related
import Home from "./views/home-related/Home";
import AboutUs from "./views/home-related/AboutUs";
import ContactUs from "./views/home-related/ContactUs";
import TermsOfUse from "./views/home-related/TermsOfUse";
import Guide from "./views/home-related/Guide";
import WorkWithUs from "./views/home-related/WorkWithUs";
import ShoppingCart from "./views/home-related/ShoppingCart";
import GiftCard from "./views/home-related/GiftCard";
import Notifications from "./views/home-related/Notifications";
// // Others
import Products from "./views/others/Products";

import Product from "./components/product/Product";
import Dashboard from "./views/others/Dashboard";
import Library from "./views/others/Library";
import {useSelector, useDispatch} from "react-redux";
import {logIn} from "./redux/actions/clientActions";
import Api from "./utils/api";
import AcceptGiftCard from "./views/home-related/AcceptGiftCard";
import LibraryProducts from "./views/others/LibraryProducts";
import Bookmark from "./views/others/Bookmark";
import PaymentVerify from "./views/others/PaymentVerify";
import {setCategories} from "./redux/actions/categoryActions";
import Reader from "./views/others/Reader";
import queryString from "query-string";
import MarketerRegister from "./views/auth/MarketerRegister";
import PublisherRegister from "./views/auth/PublisherRegister";
import NavbarContext from "./utils/contexts/Navbar";
import AuthModal from "./components/modals/authentication/others/Auth";
import EmailLogin from "./components/modals/authentication/others/EmailLogin";
import OTPModal from "./components/modals/authentication/others/OTP";
import LogOutModal from "./components/modals/authentication/others/LogOut";
import LoadingModal from "./components/modals/Loading";
import WelcomeModal from "./components/modals/Welcome";
import MaxConnectionModal from "./components/modals/authentication/others/MaxConnection";
import ForgetPasswordRequest from "./components/modals/authentication/others/ForgetPasswordRequest";
import ForgetPasswordVerify from "./components/modals/authentication/others/ForgetPasswordVerify";
import ForgetPasswordConfirm from "./components/modals/authentication/others/ForgetPasswordConfirm";
import BookIntroductions from "./views/others/BookIntroductions";
import NotFound from "./views/home-related/NotFound";
import EmailRegisterVerify from "./components/modals/authentication/others/EmailRegisterVerify";

const App = () => {
    const {pathname} = useLocation();
    const {i18n, t} = useTranslation();
    const client = useSelector(state => state.client);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [modalsDisplayStatus, setModalsDisplayStatus] = useState({
        slideMenu: false,
        auth: false,
        emailLogin: false,
        otp: false,
        emailRegisterOtp: false,
        logOut: false,
        loading: false,
        welcome: false,
        maxConnection: false,
        forgetPasswordRequest: false,
        forgetPasswordVerify: false,
        forgetPasswordConfirm: false
    });
    const [authValues, setAuthValues] = useState({
        dialCode: "+98",
        phoneNumber: "",
        emailAddress: "",
        username: "",
        resetEmailAddress: "",
        resetToken: "",
        password: "",
        inviteCode: "",
        terms: false,
        userType: "user"
    });
    const [maxConnections, setMaxConnections] = useState({
        devices: [],
        token: ""
    });

    const [preLoading, setPreLoading] = useState(true);

    axios.translator = t;
    axios.navicator = navigator;
    axios.dispatch = dispatch;
    // Functionality for scrolling to top on path change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    // Set the body direction based on language change
    useEffect(() => {
        if (i18n.language === "en") {
            document.body.style.direction = "ltr";
        } else {
            document.body.style.direction = "rtl";
        }

        if (i18n.language === "fa"){
            document.body.style.fontFamily = "IRANSans, sans-serif"
        }else{
            document.body.style.fontFamily = "Vazir, sans-serif"
        }
    }, [i18n.language]);

    useEffect(() => {
        getAuthenticationInfo();
        getCategories();
    }, []);

    const getAuthenticationInfo = async () => {
        try {
            const {data} = await axios.defaultAxios.get(Api.auth.authenticatedInfo);
            if (data.success) {
                dispatch(logIn(data.data?.user || data.data?.publisher || data.data?.marketer));
            }
        } catch (e) {

        }
        setPreLoading(false);
    };

    const getCategories = async () => {
        try {
            const {data} = await axios.defaultAxios.get(Api.public.categories);
            if (data.success) {
                dispatch(setCategories(data.data.categories));
            }
        } catch (e) {

        }
    };

    if (preLoading)
        return null;


    const renderBookRequestForm =
        pathname === "/" || pathname.startsWith("/products");

    if (pathname.startsWith("/reader")) {
        return <NavbarContext.Provider
            value={{
                modalsDisplayStatus,
                toggleModals: setModalsDisplayStatus,
                authValues,
                setAuthValues,
                maxConnections,
                setMaxConnections
            }}
        >
            <Routes>
                <Route path={"/reader"} element={<Reader/>} name={"reader"}/>
            </Routes>
        </NavbarContext.Provider>;
    }
    return (
        <div className={"app-wrapper"}>
            <div className={"app-container"}>
                <NavbarContext.Provider
                    value={{
                        modalsDisplayStatus,
                        toggleModals: setModalsDisplayStatus,
                        authValues,
                        setAuthValues,
                        maxConnections,
                        setMaxConnections
                    }}
                >
                    <Navbar/>

                    <div className={"content-container"}>
                        <Routes>
                            {/* Routes for home-related views */}
                            <Route exact path={"/"} element={<Home/>}/>
                            <Route path={"/about-us"} element={<AboutUs/>}/>
                            <Route path={"/contact-us"} element={<ContactUs/>}/>
                            <Route path={"/terms-of-use"} element={<TermsOfUse/>}/>
                            <Route path={"/guide"} element={<Guide/>}/>
                            <Route path={"/work-with-us"} element={<WorkWithUs/>}/>
                            <Route path={"/cart"} element={<ShoppingCart/>}/>
                            {client && <Route path={"/gift-card/:code/accept"} element={<AcceptGiftCard/>}/>}
                            <Route path={"/gift-card"} element={<GiftCard/>}/>
                            <Route path={"/notifications"} element={<Notifications/>}/>
                            {/* Other routes */}
                            {/*<Route path={"/categories/:type"} element={<Products />} />*/}
                            <Route path={"/products"} element={<Products/>}/>
                            <Route path={"/products/categories/:slug"} element={<Products/>}/>
                            <Route path={"/products/:slug"} element={<Product packageType={false} />} />
                            <Route path={"/products/Pakage"} element={<Product packageType={true} />} />
                            {client && <Route path={"/dashboard/:userType/*"} element={<Dashboard/>}/>}
                            {client && <Route path={"/library"} element={<Library/>}/>}
                            {client && <Route path={"/book-introductions"} element={<BookIntroductions/>}/>}
                            {client && <Route path={"/library/:type"} element={<LibraryProducts/>}/>}
                            {client && <Route path={"/bookmarks"} element={<Bookmark/>}/>}
                            <Route path="/payment/callback" element={<PaymentVerify />} />
                            <Route path={"/auth/google/callback"} element={<Reader/>}/>
                            <Route path={"/auth/marketer/register"} element={<MarketerRegister/>}/>
                            <Route path={"/auth/publisher/register"} element={<PublisherRegister/>}/>
                            <Route path={"/*"} element={<NotFound/>}/>
                        </Routes>
                    </div>

                    {renderBookRequestForm && <BookRequestForm/>}

                    <Footer/>

                    {modalsDisplayStatus.auth && <AuthModal/>}
                    {modalsDisplayStatus.emailLogin && <EmailLogin/>}
                    {modalsDisplayStatus.otp && <OTPModal/>}
                    {modalsDisplayStatus.emailRegisterOtp && <EmailRegisterVerify/>}
                    {modalsDisplayStatus.logOut && <LogOutModal/>}
                    {modalsDisplayStatus.loading && <LoadingModal/>}
                    {modalsDisplayStatus.welcome && <WelcomeModal/>}
                    {modalsDisplayStatus.maxConnection && <MaxConnectionModal/>}
                    {modalsDisplayStatus.forgetPasswordRequest && <ForgetPasswordRequest/>}
                    {modalsDisplayStatus.forgetPasswordVerify && <ForgetPasswordVerify/>}
                    {modalsDisplayStatus.forgetPasswordConfirm && <ForgetPasswordConfirm/>}
                </NavbarContext.Provider>
            </div>
        </div>
    );
};

export default App;
