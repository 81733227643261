const faTranslations = {
    // Numbers
    "24/7": "هفت روز هفته (۹ صبح تا ۶ بعد از ظهر)",

    // A
    "About us": "درباره‌ما",
    "Link Copied!": "لینک کپی شد!",
    "Active devices": "دستگاه‌های فعال",
    Activity: "فعالیت",
    "Add card": "کارت اضافه کنید",
    "Add comment": "ثبت نظر",
    "Your Comment has Been Submitted and Will Show it After Confirmation":
        "کامنت شما ثبت شد و پس از تایید نمایش داده خواهد شد",
    "Add invite code": "افزودن کد دعوت",
    "Add new product": "افزودن محصول جدید",
    "Edit Product": "ویرایش محصول",
    "Select Product Type": "نوع محصول را انتخاب کنید",
    "Collection": "مجموعه",
    "Select Category": "دسته بندی را انتخاب کنید",
    "Select Sub Category": "زیر دسته بندی را انتخاب کنید",
    "Select Filter Category": "فیلتر دسته بندی را انتخاب کنید",
    "Select Genre": "ژانر را انتخاب کنید",
    "Add new section": "افزودن بخش جدید",
    "Add new wallet": "اضافه کردن کیف پول جدید",
    "Add review": "افزودن نقد و بررسی",
    "Add your comment": "نظر خود را ثبت کنید",
    Notifications: "اعلانات",
    "All the time": "همیشه",
    "Kurdi Kermanji": "کوردی کرمانجی",
    "Kurdi Soranî": "کوردی سۆرانی",
    Amount: "مبلغ",
    "Anobani suggestions": "پیشنهاد گوشه دانش",
    "Anobani e-book": "کتاب الکترونیکی گوشه دانش",
    "Anobani gift card": "کارت اعتباری هدیه گوشه دانش",
    "Gift Card": "کارت هدیه",
    "For Using Website, Please Accept the Rules": "برای استفاده از سایت، لطفا قوانین را بپذیرید!",
    "For This Purchase, First you Need To Login.": "برای این خرید، ابتدا باید لاگین کرده باشید.",
    Apply: "اعمال",
    "Audio book": "کتاب صوتی",
    "Has Audio book": "کتاب صوتی دارد",
    "Add to Special Discounts": "محصول من در دسته محصولات ویژه برای تخفیفات در سایت قرار گیرد",
    "Audio books in this collection": "کتاب‌های صوتی این مجموعه",
    "Other Products in this collection": "دیگر محصولات این مجموعه",
    Podcasts: "پادکست ها",
    "Add Review": "افزودن نقد و بررسی",
    "Section Title": "عنوان بخش",
    "Review Text": "متن نقد",
    "Caption for Uploaded File": "توضیحات فایل آپلود شده",
    "Audio books": "کتاب های صوتی",
    "Add New Products": "افزودن محصولات جدید",
    Author: "نویسنده",
    Authors: "نویسندگان",
    "No Notifications Found": "هیچ اعلانی یافت نشد",
    "Select Currency": "ارز مورد نظر را انتخاب کنید",

    // B
    "Best sellers of the week": "پر فروش‌ترین‌های هفته",
    "Best Selling Books": "پرفروش ترین کتاب ها",
    Birthday: "تاریخ تولد",
    Blog: "وبلاگ",
    "Buy audio book": "خرید کتاب صوتی",
    "Buy e-book": "خرید کتاب الکترونیکی",
    "Buy e-books": "کتاب‌های الکترونیکی بخرید",
    "Buy journal": "خرید مجله",
    "Buy podcast": "خرید پادکست",
    "Gateway Payment": "پرداخت اینترنتی",
    "Pay with Paypal": "پرداخت با Paypal",
    "Pay with Anobani Wallet": "پرداخت با کیف پول گوشه دانش",
    "Pay with all Shetabit Cards": "(پرداخت با تمامی کارت‌های عضو شتاب)",
    "Operation Completed Successfully": "عملیات با موفقیت انجام شد",
    "You Wallet Just Charged": "کیف پول شما شارژ شد",
    "Operation Failed": "عملیات با شکست مواجه شد",
    "Add Review for Product": "ثبت نقد و بررسی برای محصول",
    // C
    Cancel: "لغو",
    Captions: "توضیحات",
    "Card number": "شماره کارت",
    "Central office address": "آدرس دفتر مرکزی",
    "Change password": "تغییر رمز عبور",
    "Choose the payment method": "روش پرداخت را انتخاب کنید",
    Code: "کد",
    Comments: "نظرات",
    Commission: "کمیسیون",
    "Common questions": "سوالات رایج",
    Confirm: "تایید",
    "Confirm and send": "ثبت و ارسال",
    "Confirm your phone number": "شماره تلفن خود را تایید کنید",
    "Confirm your Email": "ایمیل خود را تایید کنید",
    "Verify Email": "تایید ایمیل",
    Connection: "ارتباط",
    "Contact us": "با ما تماس بگیرید",
    "Contents of works": "محتویات آثار",
    "Current password": "رمز عبور فعلی",
    "Charge Wallet": "شارژ کیف پول",
    "File Upload": "آپلود فایل",
    "Your Request has been Submitted and After Confirmation, Your Wallet Will be Charged!":
        "درخواست شما ثبت شد و پس از تایید، کیف پول شما شارژ خواهد شد!",
    "Return to App": "بازگشت به برنامه",
    "Load More": "نمایش بیشتر",

    // D
    "Date / Day of payment": "تاریخ / روز پرداخت",
    Day: "روز",
    "View: Day": "مشاهده: روز",
    "View: Week": "مشاهده: هفته",
    "View: Month": "مشاهده: ماه",
    "Delete from cart": "حذف از سبر خرید",
    "Shopping Cart": "سبد خرید",
    "Device name": "نام دستگاه",
    "Electronic Book": "کتاب الکترونیکی",
    "Audio Book": "کتاب صوتی",
    Discount: "تخفیف",
    Dollars: "دلار",
    "The Latest": "جدید ترین ها",
    "The Oldest": "قدیمی ترین ها",
    "The Purchases": "پرفروش ترین ها",
    "The Populars": "محبوب ترین ها",
    Speaker: "گوینده",
    Speakers: "گویندگان",
    "Published Date": "تاریخ نشر",
    "Dollar wallet": "کیف پول دلار",
    "Do you want to be informed about the latest books, offers and new versions of book reader software by receiving emails?":
        "آیا می‌خواهید با دریافت ایمیل از آخرین کتاب‌ها، پیشنهادها و نسخه جدید برنامه کتاب‌خوان خبردار شوید؟",
    "Do you want registration, changes, user comments, etc. to be emailed to you?":
        "آیا می‌خواهد ثبت نام، تغییرات، نظرات کاربران و... برای شما ایمیل شوند؟",
    "Do you want to leave?": "آیا می‌خواهید سایت را ترک کنید؟",
    Category: "دسته بندی",
    Genre: "ژانر",
    "Product Type": "نوع",
    Publishers: "انتشارات",
    Collaborator: "نویسنده، مترجم و گوینده",
    "Sort By": "مرتب سازی",

    // E
    "E-Book": "کتاب الکترونیکی",
    "Wallet:": "کیف پول:",
    Edit: "ویرایش",
    "Email address": "آدرس ایمیل",
    "Referral Code": "کد معرف",
    "Your Referral Code": "کد معرف شما",
    Username: "نام کاربری",
    "Empty cart": "سبد خرید خالی است",
    Everywhere: "هر کجا",
    EXP: "تاریخ انقضا",
    "Error Communicating with Server": "خطا در برقراری ارتباط با سرور",
    "Error Communicating with Server, Please Try Again":
        "خطا در برقراری ارتباط با سرور، لطفا مجددا امتحان کنید",

    // F
    Female: "زن",
    "Final payment": "پرداخت نهایی",
    "First name": "نام",
    "Forgot password": "فراموشی رمز عبور",
    Free: "رایگان",
    "Upload Cover (jpeg, jpg, png)": "آپلود کاور (jpeg, jpg, png)",
    "Upload Sample (epub, pdf)": "آپلود فایل نمونه (epub, pdf)",
    "Upload Sample (mp3)": "آپلود فایل نمونه (mp3)",
    "Upload Sample (Selected)": "آپلود فایل نمونه (انتخاب شده)",
    "Sample File": "فایل نمونه",
    "Upload Main File (epub, pdf)": "آپلود فایل اصلی (epub, pdf)",
    "Upload Main File (mp3)": "آپلود فایل اصلی (mp3)",
    "Upload Main File (Selected)": "آپلود فایل اصلی (انتخاب شده)",
    "Main File": "فایل اصلی",
    "Profile Updated Successfully!": "پروفایل یا موفقیت ویرایش شد!",

    // G
    Gadgets: "ابزارک‌ها",
    "Gift card": "کارت هدیه",
    Go: "برو",
    Guide: "راهنما",

    // H
    Home: "صفحه اصلی",

    // I
    Iban: "شماره شبا",
    "I don't know": "نمی‌دانم",
    "If you have a discount code, enter it in this section":
        "اگر کد تخفیف دارید، آن را در این قسمت وارد کنید",
    "I have accepted the rules regarding the usage of Anobani.":
        "من قوانین استفاده از گوشه دانش را پذیرفته‌ام.",
    "In case of publishing publications or requesting cooperation, send us your information so that it can be confirmed after reviewing and completing the registration process for you and your team.":
        "در صورت انتشار نشریات و یا درخواست همکاری، اطلاعات خود را برای ما ارسال کنید تا پس از بررسی و تکمیل مراحل ثبت نام برای شما و تیمتان، تایید شود.",
    "In case you want to increase your stock, transfer the intended amount to the account below, and send us the receipt. Your stock will increase within 24 hours.":
        "در صورت تمایل به افزایش موجودی حساب خود، مبلغ مورد نظر را به شماره زیر واریز کرده و فیش آن را ارسال نمائید. موجودی شما طی 24 ساعت آینده افزایش پیدا می‌کند.",
    "Increase amount": "مقدار افزایش",
    "Increase inventory": "موجودی را افزایش دهید",
    "Increase stock": "افزایش موجودی کیف پول",
    "Install Anobani application": "نصب اپلیکیشن گوشه دانش",
    "Internet payment": "پرداخت اینترنتی",
    "Introduce me to a new book": "یک کتاب جدید به من معرفی کن",
    "Invite code": "کد معرف",
    Issue: "مشکل",
    "Issue tracking": "کد رهگیری",

    // J
    Journal: "مجله",

    // L
    Language: "زبان",
    Latest: "جدیدترین",
    "Last name": "نام خانوادگی",
    "Birth Date": "تاریخ تولد",
    "Latest podcasts": "پادکست‌های ماندگار",
    "Let's study": "بیایید مطالعه کنیم",
    Literature: "ادبیات",
    "Login / Signup": "ورود / ثبت نام",
    "Log in": "ورود",
    Register: "ثبت نام",
    "Log out": "خروج",

    // M
    Magazine: "مجله",
    Male: "مرد",
    Marketer: "بازاریاب",
    "Marketeer profile": "پروفایل بازاریاب",
    "Marketeer register guide file": "فایل راهنمای ثبت نام بازاریاب",
    Marketing: "بازاریابی",
    "Marketing login": "ورود به سیستم بازاریابی",
    "Membership fee": "حق عضویت",
    "Message with card": "پیام همراه کارت",
    Month: "ماه",
    "My account": "حساب من",
    "My audio books": "کتاب صوتی‌های من",
    "My books": "کتاب‌های من",
    "My E-book": "کتاب الکترونیکی من",
    "My e-books": "کتاب‌های الکترونیکی من",
    "My favorite books": "کتاب‌های مورد علاقه من",
    "My journals": "مجلات من",
    "My library": "کتابخانه من",
    "My product": "محصول من",
    "My publishers": "ناشران من",

    // N
    Name: "نام",
    "National ID": "کد ملی",
    "New journals": "مجلات جدید",
    "Journals": "مجلات",
    "New Books": "تازه ترین کتاب ها",
    "E-Books": "کتاب ها",
    Magazines: "مجلات",
    "New password": "رمز عبور جدید",
    No: "خیر",
    "Note: Each user can delete the active device up to three times during one year (Starting from the registration date).":
        "نکته: هر کاربر در طول سال (از تاریخ ثبت نام) می‌تواند تا سه بار دستگاه فعال را حذف کند.",
    "Number of times left to remove the device in this year of membership: 4":
        "تعداد دفعات باقی مانده برای حذف دستگاه در سال جاری عضویت: ۴",

    // O
    Off: "تخفیف",
    Oldest: "قدیمی‌ترین",
    "Order number": "شماره سفارش",
    "Other books by this author": "کتاب‌های دیگر این نویسنده",
    "Other books by this publisher": "کتابهای دیگر این انتشارات",

    // P
    Cash: "پولی",
    Password: "رمز عبور",
    "Password Confirmation": "تکرار رمز عبور",
    Payable: "قابل پرداخت",
    "Payment date": "تاریخ پرداخت",
    "Payment time": "زمان پرداخت",
    "Payment using Anobani wallet": "پرداخت با کیف پول گوشه دانش",
    "Personal profile": "پروفایل شخصی",
    "Phone number": "شماره موبایل",
    "Please enter the code sent via SMS in the field below.":
        "لطفا کد پیامک شده را در کادر ورودی زیر وارد کنید.",
    "Please enter the code sent via Email in the field below.":
        "لطفا کد ارسال شده شده به ایمیل را در کادر ورودی زیر وارد کنید.",
    "Please select the country code, and input your phone number.":
        "لطفا کد کشور را انتخاب کرده، و شماره موبایل خود را وارد کنید.",
    "Please upload the photos of your identity and the relevant publishing credentials.":
        "لطفا تصاویر مربوط به مدارک هویتی خود و انتشارات مربوطه را وارد نمائید.",
    "Please wait a moment": "لطفا چند لحظه صبر کنید",
    Podcast: "پادکست",
    "Postal code": "صندوق پستی",
    Price: "قیمت",
    "Final Price": "قیمت نهایی",
    "Remove From Cart": "حذف از سبد خرید",
    "Remove From Library": "حذف از کتابخانه",
    "Remove From Favorites": "حذف از علاقه مندی ها",
    "Read Online": "آنلاین مطالعه کنید",
    "Listen Online": "آنلاین گوش دهید",
    "Your Request Submitted. You are now Redirecting to Payment Gateway!":
        "درخواست شما ثبت شد. هم اکنون به درگاه پرداخت منتقل می شوید!",
    "Pages Count": "تعداد صفحات",
    "Page": "صفحه",
    "IRR": "تومان",
    "USD": "دلار",
    "IQD": "دینار",
    "Credit Card Number": "شماره کارت بانکی",
    "Credit Card Owner": "نام دارنده کارت بانکی",
    "Credit Cards": "کارت های بانکی",
    "Duration": "مدت زمان",
    "books": "کتب",
    "Problem statement": "بیان مسئله",
    Profit: "سود",
    "Sale Records": "سوابق فروش",
    "Profit of the month": "سود یک ماه",
    "Withdrawable Profit": "سود قابل برداشت",
    "Profit of the year": "سود یک سال",
    "Public domain": "دامنه عمومی",
    "Publisher name": "نام ناشر",
    "Full Name": "نام و نام خانوادگی",
    "Publisher Phone": "تلفن ناشر",
    "Logo": "لوگو",
    "Registered Date": "تاریخ ثبت نام",
    Publisher: "انتشارات",
    "Publisher profile": "پروفایل ناشر",
    "Publishers login": "ورود ناشران",
    "Publishers register file": "فایل ثبت نام ناشران",
    "Publisher's email address": "آدرس ایمیل انتشارات",
    "Publisher's code": "کد انتشارات",
    "Publisher's details": "توضیحات / معرفی انتشارات",
    "Publisher's name": "نام انتشارات",
    Publishing: "انتشارات",
    Episode: "قسمت",
    "Select Color": "انتخاب رنگ",
    "Select Font Size": "انتخاب اندازه فونت",
    "List and Settings": "فهرست و تنظیمات",
    "See all Formats And Versions": "همه فرمت ‌ها و نسخه‌ها را ببینید",
    "Electronic Version": "نسخه الکترونیکی",
    "Purchase records": "سوابق خرید",
    "Purchased books": "کتاب‌های خریداری شده",
    "Page or Resource Not Found": "صفحه یا اطلاعات مورد نظر یافت نشد",
    "Read Book": "خواندن کتاب",
    "Read Magazine": "خواندن مجله",
    "Buy E-Book": "خرید کتاب الکترونیکی",
    "Buy Audio Book": "خرید کتاب صوتی",
    "Buy Magazine": "خرید مجله",
    "Buy Podcast": "خرید پادکست",
    "Details of Podcast": "جزئیات پادکست",
    "Remove from Cart": "حذف از سبد خرید",
    "Demo": "نمونه",
    "Other Podcasts of Collection": "لیست پادکست های مجموعه",

    // R
    "Rate of study": "میزان مطالعه",
    "Receive email": "دریافت ایمیل",
    "Receiver email address": "ایمیل گیرنده هدیه",
    "Receiver name": "نام گیرنده",
    "Register reviews for the product": "ثبت نقد و بررسی برای محصول",
    "Registered users": "کاربران ثبت نام شده",
    "Register your purchase now": "همین الان خرید خود را ثبت کنید",
    "Release date": "تاریخ انتشار",
    "Not Registered": "ثبت نشده",
    "Remaining time": "زمان باقی مانده",
    "Repeat new password": "تکرار رمز عبور جدید",
    "Reply to": "پاسخ به",
    "Report card": "کارنامه",
    "Request book": "درخواست کتاب",
    "Request the book you want so we can add it to the store if possible.":
        "کتاب مورد نظر خود را درخواست کنید، تا در صورت امکان آن را به فروشگاه اضافه کنیم.",
    Resend: "ارسال مجدد",
    "Review text": "متن نقد",
    "Book Name": "نام کتاب",
    "Author Name": "نام نویسنده",
    "Publisher Name": "نام انتشارات",
    "Description / Publisher Introduction": "توضیحات / معرفی انتشارات",
    Submit: "ثبت",
    "Collection Name": "نام مجموعه",
    "Upload Image": "آپلود عکس",
    "Phone with Country Code": "شماره تلفن با کد",
    "Postal Box": "صندوق پستی",
    "Postal Code": "کد پستی",
    "Publisher Email": "پست الکترونیکی انتشارات",
    "Publisher Code": "کد انتشارات",
    "Central Address": "آدرس دفتر مرکزی",
    "Your Request has been Submitted": "درخواست شما ثبت شد",
    "Your Registration as Publisher has been Successfully Completer! You can now Login.":
        "ثبت نام شما به عنوان ناشر با موفقیت انجام شد! هم اکنون می توانید وارد حساب کاربری خود شوید.",
    "Your Registration as Marketer has been Successfully Completer! You can now Login.":
        "ثبت نام شما به عنوان بازاریاب با موفقیت انجام شد! هم اکنون می توانید وارد حساب کاربری خود شوید.",
    "Your Username and Password has been sent to your Email.":
        "نام کاربری و رمز عبور به ایمیل ارسال شد.",
    // S
    "Sales records": "سوابق فروش",
    "Sample book": "نسخه نمونه",
    Search: "جستجو",
    Season: "فصل",
    Section: "بخش",
    "Section title": "عنوان بخش",
    "See activity": "مشاهده فعالیت",
    "See all": "مشاهده همه",
    "Delete": "حذف",
    "Select wallet": "کیف پول را انتخاب کنید",
    Send: "ارسال",
    Settings: "تنظیمات",
    "Show less": "مشاهده کمتر",
    "Show more": "مشاهده بیشتر",
    "Site profit percentage": "درصد سود سایت",
    "Sign in using e-mail": "با ایمیل وارد شوید",
    "Forget Password": "بازیابی رمز عبور",
    "Sign in using e-mail address": "با استفاده از آدرس ایمیل وارد شوید",
    "Sign in using phone number": "با استفاده از شماره موبایل وارد شوید",
    "Sign in using Google account": "با استفاده از حساب گوگل وارد شوید",
    Size: "حجم",
    "Sold product": "محصول فروخته شده",
    "Sort by": "مرتب‌سازی بر اساس",
    "Currency": "ارز",
    Stock: "اعتبار",
    "Stock increase rate": "نرخ افزایش موجودی",
    "Study for everyone": "مطالعه برای همه",
    "Study time report": "گزارش زمان مطالعه",
    "Suggestion or criticism": "پیشنهاد یا انتقاد",
    Support: "پشتیبانی",
    "Recommended Books": "کتاب های پیشنهادی",

    // T
    Tax: "مالیات",
    "Terms of use": "شرایط استفاده",
    "The best gift for the best": "بهترین هدیه برای بهترین‌ها",
    "The Reader app is usable across three different devices. If you want to use a new device, first remove one from the list below.":
        "نرم‌افزار Reader در سه دستگاه مختلف قابل استفاده است. اگر می‌خواهید یک دستگاه جدید اضافه کنید، ابتدا یکی از دستگاه‌های لیست زیر را حذف کنید.",
    "This week": "این هفته",
    "This month": "این ماه",
    Title: "عنوان",
    "Title / Name on card": "نام",
    Today: "امروز",
    Toman: "تومان",
    "Toman wallet": "کیف پول تومان",
    Total: "جمع",
    "Total profit": "سود کل",
    Translator: "مترجم",
    Translators: "مترجمان",

    // U
    "Upload file": "آپلود فایل",
    "Upload file (PNG, JPG, PDF, ePUB, MP3)":
        "آپلود فایل (PNG, JPG, PDF, ePUB, MP3)",
    "Upload file (PNG, JPG)": "آپلود فایل (PNG, JPG)",
    "Upload photo": "آپلود تصویر",
    "Uploaded file details": "توضیحات فایل آپلود شده",
    User: "کاربر",

    // V
    View: "مشاهده",
    "View all": "مشاهده همه",
    "View more": "مشاهده بیشتر",
    Volume: "جلد",

    // Y
    Yes: "بله",
    "You can specify the time of sending the gift card email.":
        "شما می‌توانید زمان ارسال ایمیل کارت هدیه را مشخص کنید",
    "Your cost": "هزینه شما",
    "Discount Percent": "درصد تخفیف",
    "Start Date": "تاریخ شروع",
    "End Date": "تاریخ پایان",
    "Your credit": "اعتبار شما",
    "Your Requests Have Exceeded the Limit. Please Wait a few Seconds and Try Again":
        "تعداد درخواست های شما بیش از حد مجاز است. لطفا چند ثانیه صبر کنید و دوباره تلاش کنید",

    // W
    Wallet: "کیف پول",
    Week: "هفته",
    "We never send files": "ما به هیچ وجه فایل ارسال نمی‌کنیم",
    "What else did the buyers of this book purchase?":
        "خریداران این کتاب چه چیز دیگری خریدند؟",
    "Work with us": "با ما همکاری کنید",
    Welcome: "خوش آمدید",

    // Temp
    "Lorem ipsum xs": "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
    "Lorem ipsum s":
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.",
    "Lorem ipsum m":
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.",
    "Lorem ipsum l":
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.",
    "Lorem ipsum xxl":
        "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
    "The Secret Garden": "باغ مخفی",
    "January 14th, 1990": "1400/11/05",
    Time: "16:24",
    "Left section's description":
        "با اولین خرید از گوشه دانش می‌توانید از ۶۰ درصد تخفیف ویژه برخوردار شوید",
    "Francis Hudson Brant": "فرانسیس هادسون برنت",
    Value: "مقدار",
    Address:
        "آدرس: تهران، خیابان گاندی جنوبی، کوچه گاندی اول، پلاک ۲۲، طبقه اول - ۰۹۱۲۹۴۸۷۴۶۲",
    "We are with you for telephone support every day of the week (9 to 6 P.M) with the number: 02831286":
        "برای پشتیبانی تلفنی، در تمامی روزهای هفته (از ساعت ۹ الی ۱۸) با شماره ۰۹۱۲۸۴۷۲۶۴۸ همراه شما هستیم.",
    "For telephone support, in all day week (from ":
        "برای پشتیبانی تلفنی، در تمامی روزهای هفته (از ",
    Call: "با شماره",
    "We are with you": "همراه شما هستیم.",
    "Company Address:": "آدرس:",
    "Select Problem": "انتخاب مشکل",
    "Error in app usage or payment": "خطا در استفاده از نرم‌افزار یا پرداخت",
    "Issue details":
        "اگر پس از پرداخت و بازگشت به کتاب، آن را در بخش کتاب‌های من پیدا نکردید، به بخش کتابخانه رفته و نماد همگام‌سازی در بالای صفحه را لمس کنید. پس از آن، کتاب مورد نظر به کتابخانه شما اضافه خواهد شد.",
    "Payment issue": "مشکل در پرداخت",
    "Buying issue": "مشکل در خرید",
    "Book download issue": "مشکل در دانلود کتاب",
    "Book not added issue": "کتاب خریداری شده به کتابخانه افزوده نشد",
    "Stock not added issue": "اعتبار خریداری شده به کیف پول افزوده نشد",
    "Invite code usage issue": "پیشنهاد یا انتقاد",
    "Others issue": "سایر موارد",
    "Home page": "صفحه نخست",
    "Internet payment tip": "(پرداخت با تمامی کارت‌های عضو شتاب)",
    "Jack Toren": "جک تورن",
    "1 week ago": "یک هفته قبل",
    "Ofoqe Taban": "افق تابان",
    "Take a large library with you": "یک کتابخانه بزرگ با خود ببرید",
    "Everywhere, we always": "همه جا، ما همیشه",
    "Introduce the best harrow to you": "بهترین‌ها رو به شما معرفی می‌کنیم",
    "Travel with us to another world": "با ما به دنیایی دیگر سفر کنید",
    "The Eight Mass Market Paperback": "شومیز هشت بازار انبوه",
    "Open Access journal": "مجله دسترسی باز",
    "In Your Dreams with Chris Gethard, presented by Casper":
        "در رویاهای شما با کریس گتهارد، ارائه شده توسط کاسپر",
    "By Catherine Neville (Author), speaker by Katherine Neville":
        "توسط کاترین نویل (نویسنده)، صداگذاری توسط کاترین نویل",
    "Number 10": "شماره ۱۰",
    "With Chris Gethard and Gary Richardson": "با کریس گتهارد و گری ریچاردسون",
    "See all formats and editions": "تمامی فرمت‌ها و نسخه‌ها را ببینید",
    "Electronic version": "نسخه الکترونیکی",
    "E-book": "کتاب الکترونیکی",
    "September 20th, 2000": "۱ مهر ۱۳۷۹",
    "Ballantine Books": "افق سرخ",
    Persian: "فارسی",
    "624 pages": "۶۲۴ صفحه",
    "Review of the audio book No Friend Except for the Mountain by Behrouz Buchani":
        "نقد و بررسی کتاب صوتی هیچ دوستی جز کوه، اثر بهروز بوچانی",
    Review: "نقد و بررسی",
    "About Audio No friends except Mountain":
        "درباره کتاب صوتی هیچ دوستی به جز کوه",
    "Comments about audio book": "نظرات درباره کتاب صوتی",
    "In Your Dreams": "روز بدهی",
    "Erfan Kakaei": "عرفان کاکائی",
    "1hr, 14min, 42s": "۱ ساعت و ۱۴ دقیقه و ۴۲ ثانیه",
    "October 16th, 2020": "۱۳ مهر ۱۴۰۰",
    "0 seconds": "۰ ثانیه",
    "Authentication Failed! Please Login Again":
        "احراز هویت با مشکل مواجه شد! لطفا دوباره وارد شوید",
    Status: "وضعیت",
    Paid: "پرداخت شده",
    "Not Paid": "پرداخت نشده",
    Failed: "پرداخت نشده",
    Payment: "در انتظار پرداخت",
    Checking: "در حال بررسی",
    "Resend Code": 'ارسال مجدد کد',
    error: 'حذف با خطا مواجه شد',
    "Review Added": 'نقد و بررسی اضافه شد.',

    "Login as Marketer": "ورود به سیستم بازاریابی",
    "No Contents Found": "فهرستی یافت نشد",
    "No Highlight Found": "هایلایتی پیدا نشد",
    "No Notes Found": "یادداشتی یافت نشد",
    "No Items Found": "موردی یافت نشد",
    "Enter at least 3 Characters": "حداقل 3 کاراکتر وارد کنید",
    "Select Font": "انتخاب فونت",
    "Add Highlight": "افزودن هایلایت",
    "Add Note": "افزودن یادداشت",
    "Share": "اشتراک گذاری",
    "Text Must be Less Than 600 Character!": "متن باید کمتر از 600 کاراکتر باشد!",
    "Text Copied!": "متن کپی شد!",

    "Product Created Successfully. After the Reviews of the Admins It will be Published": "محصول با موفقیت ایجاد شد. بعد از بررسی توسط مدیران منتشر خواهد شد",
    "Product Updated Successfully. After the Reviews of the Admins It will be Published": "محصول با موفقیت ویرایش شد. بعد از بررسی توسط مدیران منتشر خواهد شد",
    "Submit Persian Version":"ثبت نسخه فارسی",
    "Submit English Version":"ثبت نسخه انگلیسی",
    "Collection Added Successfully!":"مجموعه با موفقیت ایجاد شد!"
};

export default faTranslations;
