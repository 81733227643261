import React, {useState} from "react";
import "./css/AddComment.scss";
import {useTranslation} from "react-i18next";
import TextInput from "../common/others/TextInput";
import ModalWrapper from "../common/others/ModalWrapper";
import Api from "../../utils/api";
import {getRouter} from "../../utils/helpers";
import {toast} from "react-toastify";
import axios from "../../utils/http";
import CircleLoading from "../common/others/CircleLoading";

const AddCommentModal = ({productId, toggleDisplay}) => {
    const {t} = useTranslation();
    const [title, setTitle] = useState("");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const addComment = async () => {
        if (loading)
            return;

        setLoading(true)
        try {
            const {data} = await axios.post(getRouter(Api.comments.addComment, {
                productId
            }), {
                title,
                comment
            });

            if (data.success) {
                toast(t('Your Comment has Been Submitted and Will Show it After Confirmation'), {type: 'success'});
                toggleDisplay(false);
            }

        } catch (e) {

        }
        setLoading(false)
    }

    return (
        <ModalWrapper className={"modal add-comment-modal"} toggle={() => toggleDisplay(false)}>

            <p className={'modal-title center'}>{t("Add comment")}</p>

            <TextInput
                placeholder={t("Title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />

            <textarea onChange={(e) => setComment(e.target.value)} value={comment} placeholder={t("Add your comment")}/>

            <div>
                <button type={"button"} onClick={() => addComment()} className={"button primary"}>
                    {loading ? <CircleLoading/> : t("Confirm")}
                </button>
                <button type={"button"} className={"button secondary"}>
                    {t("Cancel")}
                </button>
            </div>
        </ModalWrapper>
    );
};

export default AddCommentModal;
