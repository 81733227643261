import "./Menu.scss";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// Sub components
import Dropdown from "./sub-components/Dropdown";
// Utils
import navbarConfigs from "../../../../../../../utils/configs/navbar";
import {useSelector} from "react-redux";
import icons from "../../../../../../../utils/icons";

const Menu = () => {
    const {t} = useTranslation();
    const categories = useSelector(state => state.categories)
    console.log('categories', categories)
    return (
        <>
            <div className={"navbar-menu-desktop"}>
                <Link to={'/'}>
                    {icons.home}
                    <p>{t("Home")}</p>
                </Link>

                {categories.map((item, index) =>
                    item.link ? (
                        <Link key={item.link} to={item.link}>
                            {item.icon ? <img style={{ width: 24 }} className='mix-blend-color-burn object-contain' src={item.icon} alt="" /> : null}
                            <p>{t(item.title)}</p>
                        </Link>
                    ) : (
                        <div key={index}>
                                {item.icon ? <img style={{ width: 24 }} src={item.icon} className='mix-blend-color-burn object-contain' alt="" /> : null}
                                <p>{t(item.title)}</p>
                            <div>
                                <div/>
                                {item.sub_categories.map((subCategory, index) => (
                                    <Link
                                        key={index}
                                        to={{
                                            pathname: `/products/categories/${subCategory.slug}`,
                                            state: subCategory
                                        }}
                                    >
                                        {subCategory.title}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>

            <div className={"navbar-menu-mobile"}>
                {categories.map(
                    (category) => <Dropdown key={category.id}
                                            data={{...category, subMenu: category.sub_categories}}/>
                )}
            </div>
        </>
    );
};

export default Menu;
