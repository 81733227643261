import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useClickOutside} from "@mantine/hooks";
import "./LanguageSelector.scss";
// Utils
import icons from "../../../../../utils/icons";
import {setLocale} from "../../../../../utils/helpers";

const LanguageSelector = (props) => {
    const {i18n, t} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(null);
    const [language, setLanguage] = useState(null);
    const dropdownRef = useClickOutside(
        () => showDropdown && setShowDropdown(false)
    );
    const dropdownClassName =
        showDropdown === true
            ? "active"
            : showDropdown === false
                ? "inactive"
                : undefined;

    // function changeLanguage(locale) {
    //     setLocale(locale);
    //     // i18n
    //     //     .changeLanguage(locale)
    //     //     .then(() => {
    //     //         localStorage.setItem("locale", locale);
    //     //         // window.location.reload();
    //     //     });
    // }

    useEffect(() => {
        if (i18n.language === 'fa') {
            setLanguage(t("Persian"));
        } else if (i18n.language === 'en') {
            setLanguage(t("English"))
        } else {
            setLanguage(t("Persian"));
        }
    }, [i18n.language])

    return (
        <div
            className={`language-selector ${props.forSlideMenu ? "for-slide-menu" : ''}`}
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropdownRef}
        >
            {icons.arrowhead}
            <p>{language}</p>
            <div className={dropdownClassName}>
                <p onClick={() => setLocale("fa")}>{t("Persian")}</p>
                <p onClick={() => setLocale("en")}>{t("English")}</p>
            </div>
        </div>
    );
};

export default LanguageSelector;
