import icons from "../icons";

const navbarConfigs = {
    userDropdown: [
        {
            icon: icons.eBook,
            title: "My library",
            link: "/library",
            role: ['user']
        },
        {
            icon: icons.user,
            title: "My account",
            link: "/dashboard/{userType}/profile",
        },
        {
            icon: icons.logOut,
            title: "Log out",
        },
    ],

    tabNavigator: [
        {
            icon: icons.home,
            link: "/",
        },
        {
            icon: icons.alarmsAndNewspaper,
            link: "/notifications",
            role: ['user', 'marketer', 'publisher']
        },
        {
            icon: icons.shoppingCart,
            link: "/cart",
            role: ['user']
        },
        {
            icon: icons.giftCard,
            link: "/gift-card",
            role: ['user']
        },
    ],
};

export default navbarConfigs;
