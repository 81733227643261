import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Navbar.scss";
// Sub components
import Header from "./sub-components/header/Header";
import Footer from "./sub-components/footer/Footer";
import TabNavigator from "./sub-components/TabNavigator";

const Navbar = () => {
  const { i18n } = useTranslation();

  return (
      <div className={`navbar ${i18n.language !== "en" && "rtl"}`}>
        <Header />
        <Footer />
        <TabNavigator />
      </div>
  );
};

export default Navbar;
