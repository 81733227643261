import React, { useState } from "react";
import "./css/NewsAndComment.scss";
import { useTranslation } from "react-i18next";
import icons from "../../../utils/icons";
import { Link } from "react-router-dom";

const Notification = ({ notification }) => {
  const { i18n,t } = useTranslation();
  const [showFullNews, setShowFullNews] = useState(false);

  return (
    <>
      <div className={`news-and-comment`}>
        <div onClick={() => setShowFullNews(!showFullNews)}>
          <div>
              <p className={"title"}>{notification.title}</p>
          </div>
          <div>
            {icons.clock}
            <p>{notification.time_ago}</p>
          </div>
        </div>

        {showFullNews && <div className={"content"}>
          <pre>
            {notification.text}
          </pre>
          {notification.status === "new_book" ? <div
            style={{ direction: i18n.language === "en" ? "rtl" : "ltr" }}
            className={"notification-action-container"}>
            <Link class={"notification-action-button"}
                  to={`/products/${notification.product.slug}`}>{t("View")}</Link>
          </div> : null}
        </div>}
      </div>
    </>
  );
};

export default Notification;
