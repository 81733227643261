import React, {useEffect, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import OTPInput from "otp-input-react";
import "./css/OTP.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import auth from "./Auth";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";

const EmailRegisterVerify = () => {
    const {t} = useTranslation();
    const {modalsDisplayStatus, toggleModals, authValues, setAuthValues} = useContext(NavbarContext);

    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(120);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let interval = null;
    useEffect(() => {
        interval = setInterval(() => {
            timer > 0 && setTimer((prevState) => prevState - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timer]);

    function toggle() {
        toggleModals({...modalsDisplayStatus, emailRegisterOtp: false});
        setAuthValues({
            ...authValues, emailAddress: '', password: ''
        })
    }

    function renderTimer() {
        const minutes = Math.floor(timer / 60);
        const minutesToShow = minutes < 10 ? "0" + minutes : minutes;
        const seconds = timer % 60;
        const secondsToShow = seconds < 10 ? "0" + seconds : seconds;
        return minutesToShow + ":" + secondsToShow;
    }

    const resendOTP = async () => {
        setLoading(true);
        try {
            const {data} = await axios.post(API.auth.userLogin, {
                email: authValues.emailAddress,
                password: authValues.password
            });

            if (data.success) {
                if (data.data?.code)
                    toast(data.data?.code, {type: 'success'})
                setTimer(120);
            }
        } catch (e) {

        }
        setLoading(false);
    }

    const handleChangeOTP = async (value) => {
        setOtp(value);

        if (value.length !== 5)
            return;

        try {
            const {data} = await axios.post(API.auth.userLogin, {
                email: authValues.emailAddress,
                password: authValues.password,
                otp: value
            });

            if (data.success) {
                const {user, token} = data.data;
                localStorage.setItem('token', token);
                axios.setAuthorizationHeader(token);
                dispatch(logIn(user));
                toggleModals({...modalsDisplayStatus, emailRegisterOtp: false});
                setAuthValues({
                    ...authValues, emailAddress: '', password: ''
                })
                clearInterval(interval);
            }
        } catch (e) {

        }
    }

    return (
        <ModalWrapper className={"otp-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Verify Email")}</p>
            <p className={"modal-tip"}>{t("Confirm your Email")}</p>
            <div>
                <p>{authValues.emailAddress}</p>
                <button
                    onClick={() => toggleModals({
                        ...modalsDisplayStatus,
                        emailRegisterOtp: false,
                        emailLogin: true
                    })}>{t("Edit")}</button>
            </div>
            <p>{t("Please enter the code sent via Email in the field below.")}</p>
            <OTPInput
                value={otp}
                onChange={handleChangeOTP}
                autoFocus
                OTPLength={5}
                otpType="number"
                className={"otp-input"}
            />
            {timer !== 0 && <div className={"center"}>
                <p>{renderTimer()}</p>
                {icons.progressRing}
            </div>}
            <p className={"center"}>{t("Remaining time")}</p>
            {timer === 0 && <button type={"button"} className={"button primary"} onClick={resendOTP}>
                {t("Resend")}
            </button>}

        </ModalWrapper>
    );
};

export default EmailRegisterVerify;
