import React from "react";
import "./css/LeftSection.scss";
import {useTranslation} from "react-i18next";
// Utils
import tempMedia from "../../../../utils/tempMedia";
import {useNavigate} from "react-router-dom";

const LeftSection = () => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const navigator = useNavigate();

    return (
        <div className={"footer-left-section"}>
            <div>
                <div>
                    <p className={i18n.language === "en" ? "ltr" : ''} style={{fontSize: 28}}>
                        {t("Off")}
                    </p>
                    <p>{t("Buy e-books")}</p>
                </div>

                <div/>

                <div>
                    <div>
                        <p>{t("Left section's description")}</p>
                        <p onClick={() => navigator('/products')}>{t("Register your purchase now")}</p>
                    </div>

                    <p onClick={() => navigator('/products')}>{t("Go")}</p>
                </div>
            </div>

            <div>
                <img
                    alt={"Namade etemade electronic"}
                    src={tempMedia.others.namadeEtemadeElectronic}
                />
                <img
                    alt={"Neshane melli sabt"}
                    src={tempMedia.others.neshaneMelliSabt}
                />
            </div>
        </div>
    );
};

export default LeftSection;
